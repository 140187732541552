<template>
  <div class="flex gap-3">
    <div class="relative flex items-center gap-1 z-50">
      <div
        v-if="loading"
        class="h-8 skeleton w-36 rounded-xl"
      />
      <div v-else>
        <BaseSingleDropdown
          :trigger-class="'h-8'"
          :selected-obj="selectedPreset"
          :options="getPresets || []"
          min-width="130px"
          :placeholder="getIsCustomPreset ? 'Custom' : 'Select a preset'"
          empty-state-text="You have no presets"
          :option-label="(opt) => opt.name"
          :selected-label="(opt) => `Preset ${getIsCustomPreset ? 'Custom' : opt?.name}`"
          @change="updateCurrentPreset"
        >
          <template #icon>
            <PresetIcon class="text-icon-normal" />
          </template>
        </BaseSingleDropdown>
      </div>
    </div>
    <div
      v-on-clickaway="() => { showDropdown.tableSettings = false }"
      class="relative"
    >
      <div
        v-if="loading "
        class="h-8 w-36 skeleton rounded-xl"
      />
      <button
        v-else
        class="flex items-center gap-1 base-btn h-8 px-2 py-1.5 rounded-lg"
        :class="{'expanded': showDropdown.tableSettings}"
        @click="showDropdown.tableSettings = !showDropdown.tableSettings"
      >
        <SettingsIcon class="text-icon-normal" />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Table Settings
        </BaseText>
        <div
          class="transform transition-transform duration-200"
          :style="showDropdown.tableSettings ? 'transform: scale(1, -1)' : ''"
        >
          <ChevronIcon class-name="text-icon-normal" />
        </div>
      </button>

      <transition name="fade">
        <div
          v-if="showDropdown.tableSettings"
          class="p-1 absolute left-0 mt-1 border border-border-normal bg-white overflow-y-hidden dropdown-scrollable shadow-md rounded-lg"
          style="width: 350px"
        >
          <!-- Color formatting -->
          <div class="flex items-center justify-between gap-x-2 setting-option z-20 relative">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Color formatting
            </BaseText>
            <BaseSingleDropdown
              :selected-obj="selectedColorFormat"
              :options="colorFormatOptions"
              :min-width="'160px'"
              :option-label="(opt) => opt.name"
              placeholder="Select a format"
              :trigger-class="'h-7'"
              @change="updateTableSettings('colorFormat', $event.id)"
            >
              <template #icon>
                <div
                  class="flex pl-1.5"
                >
                  <div
                    class="h-3.5 rounded-l-sm"
                    :style="getOutlierStyle(selectedColorFormat?.id, 'left')"
                  />
                  <div
                    class="h-3.5 rounded-r-sm"
                    :style="getOutlierStyle(selectedColorFormat?.id, 'right')"
                  />
                </div>
              </template>
              <template #option-icon="{option}">
                <div
                  class="flex mr-1"
                >
                  <div
                    class="h-3.5 rounded-l-sm"
                    :style="getOutlierStyle(option?.id, 'left')"
                  />
                  <div
                    class="h-3.5 rounded-r-sm"
                    :style="getOutlierStyle(option?.id, 'right')"
                  />
                </div>
              </template>
            </BaseSingleDropdown>
          </div>
          <!-- Results per page row -->
          <div class="flex items-center justify-between setting-option">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted col-span-8 z-10 relative"
            >
              Results per page
            </BaseText>
            <div
              v-on-clickaway="() => { showDropdown.resultsPer = false }"
              class="relative w-max"
              @click="showDropdown.resultsPer = !showDropdown.resultsPer"
            >
              <div
                class="flex gap-2 h-7 items-center p-2 py-1.5 rounded-md bg-white hover:bg-background-normal border border-border-normal cursor-pointer transition-colors"
                :class="`${showDropdown.resultsPer && 'bg-background-normal'}`"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ tablePagination.limit }}
                </BaseText>
                <div
                  class="transform transition-transform duration-200"
                  :style="showDropdown.resultsPer ? 'transform: scale(1, -1)' : ''"
                >
                  <ChevronIcon class-name="text-icon-disabled" />
                </div>
              </div>
              <transition name="fade">
                <div
                  v-if="showDropdown.resultsPer"
                  style="z-index: 99999"
                  class="absolute w-full min-w-max bg-white right-0 p-1 overflow-y-auto max-h-40 dropdown-scrollable  shadow-md rounded-lg flex flex-col gap-2"
                >
                  <button
                    v-for="option in paginationOptions"
                    :key="option.name"
                    class="w-full bg-white hover:bg-background-normal rounded-md py-1 px-2 text-left flex items-center gap-1"
                    :class="{ 'bg-background-normal': option === tablePagination.limit }"
                    @click="updateTablePagination('limit', option)"
                  >
                    <BaseText size="sm">
                      {{ option }}
                    </BaseText>
                    <div v-show="option === tablePagination.limit">
                      <CheckmarkIcon />
                    </div>
                  </button>
                </div>
              </transition>
            </div>
          </div>
          <!-- Status row -->
          <div class="flex justify-between items-center setting-option">
            <BaseText
              size="sm"
              type="label"
              class="text-text-muted"
            >
              Show status
            </BaseText>
            <BaseToggle
              :value="!!tableConfig?.showStatus"
              @toggle="updateTableSettings('showStatus', !tableConfig?.showStatus)"
            />
          </div>
          <!-- Tags row -->
          <div class="flex justify-between items-center setting-option">
            <BaseText
              size="sm"
              type="label"
              class="text-text-muted"
            >
              Show tags
            </BaseText>
            <BaseToggle
              :value="!!tableConfig?.showTags"
              @toggle="updateTableSettings('showTags', !tableConfig?.showTags)"
            />
          </div>
          <!-- Divider -->
          <div class="w-full h-0.5 border-t border-border-normal my-3" />
          <!-- included kpis -->
          <div class=" max-h-44 dropdown-scrollable overflow-y-auto">
            <SelectedKPISidebar
              hide-title
              no-padding
              no-border
              :columns="tableColumns"
              @change="handleKpiChange"
            />
          </div>
          <!-- Divider -->
          <div class="w-full h-0.5 border-t border-border-normal my-3" />
          <button
            class="w-full flex items-center gap-1.5 hover:bg-background-hover p-1.5 rounded-md cursor-pointer transition-colors relative"
            @click="showColumnDrawer"
          >
            <PlusIcon class="text-icon-normal" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Add Metric
            </BaseText>
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import { mapGetters, mapMutations } from 'vuex'

// Icons
import SettingsIcon from '../../globals/Icons/SettingsIcons/SettingsIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
// Components
import SelectedKPISidebar from '../settings/SelectedKPISidebar.vue'
import { mapActions } from 'vuex/dist/vuex.common.js'
import BaseSingleDropdown from '../../globals/BaseSingleDropdown.vue'
import PresetIcon from '../../globals/Icons/LensIcons/PresetIcon.vue'

export default {
  components: {
    // Components
    SelectedKPISidebar,
    BaseSingleDropdown,

    // Icons
    SettingsIcon,
    ChevronIcon,
    CheckmarkIcon,
    PlusIcon,
    PresetIcon
  },
  mixins: [clickaway],
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    tablePagination: {
      type: Object,
      default: () => {}
    },
    tableConfig: {
      type: Object,
      default: () => {}
    },
    tableColumns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const colorFormatOptions = [
      {
        name: 'None',
        id: 'none'
      },
      {
        name: 'Positive',
        id: 'positive'
      },
      {
        name: 'Negative',
        id: 'negative'
      },
      {
        name: 'Positive & Negative',
        id: 'positive_negative'
      }
    ]

    const colorFormatIcons = {
      none: {
        light: '#F6F8FA',
        mid: '#DFE1E7',
        dark: '#808899'
      },
      negative: {
        light: '#FDD9E0',
        mid: '#ED8296',
        dark: '#DF1C41'
      },
      positive: {
        light: '#D8F3EE',
        mid: '#9EE1D4',
        dark: '#40C4AA'
      },
      positive_negative: {
        mid: '#D8F3EE',
        light: '#fdd9e0',
        borderLeft: '#40c4aa',
        borderRight: '#df1c41'

      }
    }
    return {
      paginationOptions: [],
      colorFormatOptions,
      colorFormatIcons,
      showDropdown: {
        tableSettings: false,
        resultsPer: false
      }
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getLocalPreset', 'getPresets', 'getAppliedPreset', 'getIsCustomPreset']),
    selectedColorFormat () {
      const colorFormat = this.tableConfig.colorFormat
      if (!colorFormat) return this.colorFormatOptions[0]
      return this.colorFormatOptions.find((opt) => opt.id === colorFormat)
    },
    selectedPreset () {
      return this.getIsCustomPreset ? null : (this.getAppliedPreset ?? null)
    }
  },
  watch: {
    loading (newVal) {
      if (!newVal && this.paginationOptions.length === 0) {
        const totalPages = this.tablePagination.totalPages
        this.paginationOptions = Array.from({ length: totalPages }, (_, i) => (i + 1) * 10)
      }
    }
  },
  methods: {
    ...mapActions('LensModule', ['applyPreset', 'setPresetById', 'updateIsCustomPreset', 'updateColorFormat']),
    ...mapMutations('LensModule', ['SET_COLUMN_DRAWER_VIEW']),
    getOutlierStyle (id, dir) {
      let borderStyles = {}
      const borderColor = this.colorFormatIcons[id]?.[dir === 'left' ? 'borderLeft' : 'borderRight'] ?? this.colorFormatIcons[id]?.dark
      const horizontalBorder = dir === 'left' ? 'borderLeft' : 'borderRight'
      if (borderColor) {
        borderStyles = {
          borderTop: `2px solid ${borderColor}`,
          borderBottom: `2px solid ${borderColor}`,
          [horizontalBorder]: `2px solid ${borderColor}`
        }
      }

      const bgColor = dir === 'left' ? this.colorFormatIcons[id]?.mid : this.colorFormatIcons[id]?.light
      return {
        width: '7px',
        backgroundColor: bgColor,
        ...borderStyles
      }
    },
    showColumnDrawer () {
      this.SET_COLUMN_DRAWER_VIEW(true)
    },
    handleKpiChange () {
      this.applyPreset(this.getLocalPreset)
      const newTableCols = this.getAppliedPreset.table_columns.map((col) => {
        const reportCol = this.tableColumns.find((tableCol) => tableCol.key === col)
        return {
          key: col,
          is_pinned: reportCol.is_pinned
        }
      })
      this.updateIsCustomPreset(true)
      this.$emit('reportChanged', { type: 'table_columns', value: newTableCols, preventFetch: true })
    },
    handlePinChange (newPins) {
      this.$emit('reportChanged', { type: 'pinned_columns', value: newPins, preventFetch: true })
    },
    updateCurrentPreset (preset) {
      if (!this.getIsCustomPreset && preset?.id === this.getAppliedPreset?.id) return
      this.setPresetById({ id: preset.id, shouldSelect: true })
      const newAppliedPreset = this.getAppliedPreset
      const newTableCols = newAppliedPreset.table_columns.map((col) => {
        return {
          key: col,
          is_pinned: false
        }
      })

      this.updateIsCustomPreset(false)
      this.$emit('reportChanged', { type: 'preset_id', value: preset.id, preventFetch: true })
      this.$emit('reportChanged', { type: 'table_columns', value: newTableCols, preventFetch: true })
    },
    updateTablePagination (type, value) {
      this.$emit('paginationChanged', { type, value })
    },
    updateTableSettings (key, value) {
      if (key === 'colorFormat') {
        this.updateColorFormat(value)
      }
      const toUpdate = {
        ...this.tableConfig,
        [key]: value
      }
      // Prevent fetch if not a pagination update
      this.$emit('reportChanged', { type: 'table_config', value: toUpdate, preventFetch: true })
    }
  }
}
</script>

<style scoped>
.dropdown-scrollable {
  transition: colors 250ms ease-in-out;
  top: 100%;
  z-index: 999;
}

.dropdown-scrollable::-webkit-scrollbar {
  width: 3px;
}

.dropdown-scrollable::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}

.base-btn:hover, .base-btn.expanded {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}

.setting-option {
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 150ms !important;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
