<template>
  <BaseDrawer
    no-padding
    height-auto-only
    no-toolbar
    no-bottom-rounding
    class="scrollbar-hide"
    close-label="column customization"
    @close="handleNoSaveClose"
  >
    <template #content>
      <div
        class="w-full flex flex-col"
        style="height: calc(100vh - 80px)"
      >
        <!-- Toolbar -->
        <div
          class="w-full content-grid gap-0.5 h-14 justify-between py-1 border-b border-border-normal"
        >
          <!-- Label -->
          <div class="flex items-center p-1.5 gap-1 px-4">
            <CustomizeIcon class="text-icon-muted" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted whitespace-nowrap"
            >
              Customize Columns
            </BaseText>
          </div>
          <!-- Middle options -->
          <div class="py-1">
            <ManagePresetHeader
              :has-selected-preset="hasSelectedPreset"
              @edit-preset="showEditModal = true"
              @selectPreset="handlePresetSelect"
            />
          </div>
          <!-- Apply actions -->
          <div class="justify-self-end flex gap-3 items-center pr-4">
            <button
              class="group h-8 px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
              @click="handleNoSaveClose"
            >
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted transition-colors duration-100 group-hover:text-text-loud"
              >
                Cancel
              </BaseText>
            </button>
            <button
              class="h-8 black-btn-primary px-3 py-1.5 rounded-md"
              @click="onApply"
            >
              <BaseText
                type="label"
                size="sm"
                class="text-white"
              >
                Apply
              </BaseText>
            </button>
          </div>
        </div>
        <div class="content-grid flex-grow h-full min-h-0">
          <!-- Sidebar navigation -->
          <div
            class="h-full flex flex-col justify-between border-r border-border-normal p-4 overflow-y-auto scrollable-section"
            style="height: calc(100vh - 136px)"
          >
            <div class="w-full flex flex-col gap-1">
              <!-- Foreplay Metrics -->
              <div>
                <div
                  class="flex items-center gap-2 p-3 rounded-lg fp-metrics-btn bg-neutral-900 text-white mb-2 cursor-pointer transition-colors relative overflow-hidden"
                >
                  <FPLensMetricsIcon
                    class="absolute -right-7"
                    :width="70"
                    :height="70"
                  />
                  <component
                    :is="fpSidebarNavOption.icon"
                    class="transition-colors duration-100"
                  />
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ fpSidebarNavOption.name }}
                  </BaseText>
                </div>
                <div
                  class="border-l border-border-normal ml-4 pl-4 flex flex-col gap-2"
                >
                  <div
                    v-for="(option, i) in fpSidebarNavOption.childRoutes"
                    :key="`child-nav-option-${i}`"
                    class="nav-btn py-1 rounded-md"
                    :class="showNavActive(option.viewMode)"
                    @click="
                      handleViewModeNavigation(option.viewMode, option.viewMode)
                    "
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-text-muted pl-2 cursor-pointer"
                    >
                      {{ option.name }}
                    </BaseText>
                  </div>
                </div>
              </div>
              <!-- External Metrics  -->
              <div
                v-for="(navOptions, indexNav) in columnNavigation"
                :key="`nav-option-${indexNav}`"
              >
                <div
                  v-if="navOptions.name"
                  class="flex items-center gap-2 p-1.5"
                >
                  <component
                    :is="navOptions.icon"
                    class="flex-shrink-0 text-icon-disabled"
                  />
                  <BaseText
                    class="text-text-subdued flex-shrink-0 uppercase"
                    type="heading"
                    size="overline"
                  >
                    {{ navOptions.name }}
                  </BaseText>
                  <div class="w-full border-t border-border-normal" />
                </div>
                <!-- Section header -->
                <div
                  v-for="(navOpt, index) in navOptions.childRoutes"
                  :key="`nav-option-${index}`"
                >
                  <div
                    class="nav-btn cursor-pointer flex items-center gap-2 p-1.5 py-2.5 rounded-lg"
                    :class="showNavActive(navOpt.viewMode)"
                    @click="
                      handleViewModeNavigation(navOpt.viewMode, navOpt.viewMode)
                    "
                  >
                    <component
                      :is="navOpt.icon"
                      class="transition-colors duration-100"
                    />
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-text-muted"
                    >
                      {{ navOpt.name }}
                    </BaseText>
                  </div>
                  <!-- Section children -->
                  <div
                    class="border-l border-border-normal ml-4 pl-4 flex flex-col"
                  >
                    <div
                      v-for="(option, i) in navOpt.childRoutes"
                      :key="`child-nav-option-${i}`"
                      class="nav-btn py-1 rounded-md"
                      :class="showNavActive(option.viewMode)"
                      @click="
                        handleViewModeNavigation(option.viewMode, navOpt.name)
                      "
                    >
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-text-muted pl-2 cursor-pointer"
                      >
                        {{ option.name }}
                      </BaseText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Content View -->
          <div
            ref="scrollContainer"
            class="w-full h-full overflow-y-auto relative p-4 flex flex-col items-center scrollable-section"
          >
            <div
              style="min-width: 575px; max-width: 700px;"
            >
              <ForeplayMetricsSection
                ref="fpMetrics"
                :preset-calculated-scores="getLocalPreset.calculatedScores"
              />
              <MetaMetricsSettings
                ref="metaMetrics"
                :has-selected-preset="hasSelectedPreset"
                :preset="getLocalPreset"
                :columns="tableColumns"
                @updateCols="updateShownColumns"
              />
            </div>
          </div>
          <!-- Selected KPI's -->
          <div class="overflow-y-auto relative scrollable-section mr-1">
            <SelectedKPISidebar
              :columns="shownColumns"
            />
          </div>
        </div>
      </div>
    </template>
  </BaseDrawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

// COMPONENTS
import ManagePresetHeader from '../lens/settings/ManagePresetHeader.vue'
import SelectedKPISidebar from '../lens/settings/SelectedKPISidebar.vue'

// VIEW COMPONENTS
import ForeplayMetricsSection from '../lens/settings/sections/ForeplayMetricsSection.vue'
import MetaMetricsSettings from '../lens/settings/sections/MetaMetricsSettings.vue'

// ICONS
import CustomizeIcon from '../globals/Icons/LensIcons/CustomizeIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import LogoutIcon from '../globals/Icons/LogoutIcon.vue'
import LockedIcon from '../globals/Icons/LockedIcon.vue'
import MetricsIcon from '../globals/Icons/LensIcons/MetricsIcon.vue'
import FPLensMetricsIcon from '../globals/Icons/LensIcons/FPLensMetricsIcon.vue'
import * as ViewIcons from '../globals/Icons/LensIcons'
import sectionIcons from '../../utils/lens/metricIconMap'
export default {
  name: 'LensSettings',
  components: {
    // Icons
    CustomizeIcon,
    TagRemoveIcon,
    LogoutIcon,
    LockedIcon,
    FPLensMetricsIcon,
    // Components
    ManagePresetHeader,
    SelectedKPISidebar,
    // Views & Sections
    ForeplayMetricsSection,
    MetaMetricsSettings
  },
  props: {
    tableColumns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    // Sub item routes
    const childRoutes = {
      metrics: [{ name: 'Calculated Scores', viewMode: 'calculated scores' }],
      performance: [
        {
          name: 'Ad Relevance Diagonistics',
          viewMode: 'ad relevance diagnostics'
        },
        { name: 'Cost', viewMode: 'cost' }
      ],
      engagement: [
        { name: 'Page Post', viewMode: 'page post' },
        { name: 'Calling', viewMode: 'calling' },
        { name: 'Messaging', viewMode: 'messaging' },
        { name: 'Media', viewMode: 'media' },
        { name: 'Clicks', viewMode: 'clicks' },
        { name: 'Awareness', viewMode: 'awareness' }
      ],
      conversions: [
        { name: 'Standard Events', viewMode: 'standard events' },
        { name: 'Custom Conversions', viewMode: 'custom conversions' },
        { name: 'Custom Events', viewMode: 'custom events' }
      ],
      settings: [
        { name: 'Object Names & IDs', viewMode: 'object names ids' },
        { name: 'Status & Dates', viewMode: 'status dates' },
        { name: 'Goal, Budget & Schedule', viewMode: 'goal budget schedule' },
        { name: 'Targeting', viewMode: 'targeting' },
        { name: 'Ad Creative', viewMode: 'ad creative' },
        { name: 'Tracking', viewMode: 'tracking' }
      ]
    }
    // Header navigation options
    const fpSidebarNavOption = {
      name: 'Foreplay Metrics',
      icon: MetricsIcon,
      childRoutes: [
        {
          name: 'Calculated Scores',
          viewMode: 'calculated scores',
          childRoutes: childRoutes.metrics
        }
      ]
    }

    // Primary table of contents side bar navigation options
    const sidebarNavOptions = [
      {
        name: 'All Meta Metrics',
        icon: ViewIcons.MetaMetricsIcon,
        childRoutes: [
          {
            name: 'Performance',
            icon: ViewIcons.PerformanceIcon,
            viewMode: 'performance',
            childRoutes: childRoutes.performance
          },
          {
            name: 'Engagement',
            icon: ViewIcons.EngagementIcon,
            viewMode: 'engagement',
            childRoutes: childRoutes.engagement
          },
          {
            name: 'Conversions',
            icon: ViewIcons.ConversionIcon,
            viewMode: 'conversions',
            childRoutes: childRoutes.conversions
          },
          {
            name: 'Settings',
            icon: ViewIcons.SettingsIcon,
            viewMode: 'settings',
            childRoutes: childRoutes.settings
          },
          { name: 'A/B Test', icon: ViewIcons.ABIcon, viewMode: 'ab' },
          {
            name: 'Optimization',
            icon: ViewIcons.OptimizationIcon,
            viewMode: 'optimization'
          }
        ]
      },
      {
        name: 'GA Metrics',
        icon: ViewIcons.GAMetricsIcon,
        childRoutes: []
      }
    ]

    const metricIcons = sectionIcons
    return {
      metricIcons,

      sidebarNavOptions,
      fpSidebarNavOption,

      currentViewMode: '',
      showEditModal: false,

      sectionObserver: null,
      settingSections: [],

      isScrolling: false,

      // Locally defines if the current state is not custom
      hasSelectedPreset: false,

      shownColumns: [],

      columnNavigation: []
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetrics']),
    ...mapGetters('LensModule', [
      'getAppliedPreset',
      'getLocalPreset',
      'getPresets',
      'getIsCustomPreset'
    ])
  },
  async mounted () {
    console.log(this.getMetrics)
    this.initSidebarNav()
    // this.fetchPresets()
    this.settingSections = document.querySelectorAll('.metric-section')
    this.createSectionObserver()
    this.shownColumns = this.tableColumns
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    ...mapActions('LensModule', ['updatePreset', 'fetchPresets', 'applyPreset', 'updateIsCustomPreset']),
    initSidebarNav () {
      const metricGroups = Object.keys(this.getMetrics)
      // Fallback
      if (metricGroups.length < 1) return this.sidebarNavOptions

      // TODO @Sam: generate groups from custom + static (retool) metrics - once custom is implemented
      const groups = metricGroups.map((group) => {
        const childRoutes = this.getMetrics[group].subSections.map((subGroup) => {
          return {
            name: _.startCase(subGroup.sectionId),
            viewMode: subGroup.domId
          }
        })
        return {
          name: _.startCase(group),
          icon: this.metricIcons[group],
          viewMode: group,
          childRoutes: childRoutes
        }
      })

      this.columnNavigation = [{
        name: 'All Meta Metrics',
        icon: ViewIcons.MetaMetricsIcon,
        childRoutes: groups
      }]
    },
    handlePresetSelect (presetId) {
      this.hasSelectedPreset = true
      this.shownColumns = this.getPresets.find((preset) => preset.id === presetId)?.table_columns.map((col) => ({ key: col, is_pinned: false }))
    },
    updateShownColumns (cols) {
      this.shownColumns = cols.map((col) => {
        const originalCol = this.tableColumns.find((tableCol) => tableCol.key === col)
        return {
          key: col,
          is_pinned: originalCol ? originalCol.is_pinned : false
        }
      })
    },
    emitTableColChange (cols) {
      const newTableCols = cols.map((col) => {
        return {
          key: col,
          is_pinned: col.is_pinned
        }
      })

      this.$emit('reportChanged', { type: 'table_columns', value: newTableCols, preventFetch: true })
    },
    emitPresetIdChange (id) {
      this.$emit('reportChanged', { type: 'preset_id', value: id, preventFetch: true })
    },
    showNavActive (optionView) {
      return optionView === this.currentViewMode
        ? 'bg-background-normal z-10'
        : 'z-20'
    },
    createSectionObserver () {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.isScrolling) {
              this.currentViewMode = entry.target?.id
            }
          })
        },
        {
          root: this.$refs.scrollContainer,
          rootMargin: '0px 0px -93% 0px',
          threshold: 0
        }
      )

      this.settingSections.forEach((section) => {
        observer.observe(section)
      })
    },
    handleViewModeNavigation (viewMode, parentView) {
      this.isScrolling = true
      const onScrollEnd = (e) => {
        this.isScrolling = false
        this.$refs.scrollContainer.removeEventListener(
          'scrollend',
          onScrollEnd
        )
      }
      this.$refs.scrollContainer.addEventListener('scrollend', onScrollEnd)
      this.$nextTick(() => {
        const refView = parentView.toLowerCase()
        const metricRefs =
          this.$refs.metaMetrics?.$refs[refView][0] ?? this.$refs?.fpMetrics
        const targetElements = metricRefs?.$refs[viewMode]
        if (targetElements) {
          const anchor = Array.isArray(targetElements)
            ? targetElements[0]
            : targetElements
          this.currentViewMode = viewMode
          anchor.$el.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
    onApply () {
      const currentApply = this.getAppliedPreset
      const toApply = this.getLocalPreset

      this.applyPreset(toApply)

      const isSamePreset = toApply.id === currentApply.id
      const originalToApply = this.getPresets.find((preset) => preset.id === toApply.id)

      /**
       * Handles the custom preset flow
       *
       * If we choose a different preset, update both cols & id
       * If we manipulated the columns from the original, emit col change and set custom
       */
      if (!isSamePreset) {
        this.emitPresetIdChange(toApply.id)
        this.emitTableColChange(toApply.table_columns)
        this.updateIsCustomPreset(false)
      } else if (this.hasSelectedPreset) {
        // If we selected a preset and was previously custom
        if (this.getIsCustomPreset) {
          this.emitPresetIdChange(toApply.id)
          this.emitTableColChange(toApply.table_columns)
        }
        this.updateIsCustomPreset(false)
      }

      if (!_.isEqual(toApply.table_columns, originalToApply.table_columns)) {
        this.updateIsCustomPreset(true)
        this.emitTableColChange(toApply.table_columns)
      }

      this.$emit('close')
    },
    handleNoSaveClose () {
      const revertTo = this.getAppliedPreset
      this.applyPreset(revertTo)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.scrollable-section::-webkit-scrollbar {
  width: 3px;
}
.scrollable-section::-webkit-scrollbar-thumb {
  background-color: #dfe1e7;
  border-radius: 18px;
}
.content-grid {
  display: grid;
  grid-template-columns: minmax(215px, 300px) minmax(635px, 1fr) minmax(
      215px,
      300px
    );
  width: 100%;
}

.logout-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13),
    0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: white;
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}

.logout-btn:hover {
  box-shadow: none;
  background-color: #fff0f3;
}

.nav-btn {
  position: relative;
}
.nav-btn:hover {
  box-shadow: 0px 0px 0px 1px rgba(236, 239, 243, 1),
    0px 1px 2px 0px rgba(9, 25, 72, 0.08);
}
.fp-metrics-btn:hover {
  background-color: rgba(6, 7, 15, 0.92);
}
.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13),
    0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
}
.base-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17),
    0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
</style>
