import staticFilterOptions from './staticFilterOptions'
import dymamicFilterOptions from './dynamicFilterOptions'
import { nanoid } from 'nanoid'

export function createFilterQuery (appliedFilters, dateRange = null) {
  const andConditions = [...appliedFilters]
  const queryPayload = []
  for (const orConditions of andConditions) {
    queryPayload.push(orConditions.map(condition => ({
      key: condition.property.key,
      operation: condition.operation.key,
      value: condition.value
    })))
  }
  if (dateRange && dateRange.start && dateRange.end) {
    queryPayload.push(...createDateRangeQuery(dateRange))
  }
  return queryPayload
}

function createDateRangeQuery (dateRange) {
  if (dateRange.isRelativeToToday) {
    // Determine the difference in days between the start and end date
    const diffTime = Math.abs(dateRange.end - dateRange.start)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    return [
      [{ key: 'event_date', operation: 'greater_than', value: `last_${diffDays}_days` }],
      [{ key: 'event_date', operation: 'less_than', value: 'today' }]
    ]
  } else {
    const formatDateString = (date) => {
      // Convert to YYYY-MM-DD format
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }
    const { start, end } = dateRange
    return [
      [{ key: 'event_date', operation: 'greater_than', value: formatDateString(start) }],
      [{ key: 'event_date', operation: 'less_than', value: formatDateString(end) }]
    ]
  }
}

export function parseFiltersFromQuery (queryPayload) {
  if (!queryPayload && queryPayload?.length === 0) return []
  // First we create a map of all the filter options
  const filterOptionsMap = new Map()
  const allFilterCategories = [...staticFilterOptions, ...dymamicFilterOptions()]
  allFilterCategories.flatMap(category => category.options).forEach(option => {
    if (!filterOptionsMap.has(option.key)) {
      filterOptionsMap.set(option.key, option)
    }
  })
  // Now we parse the query payload into the options and operations
  const parsedQuery = queryPayload.map(orConditions => {
    return orConditions.map(condition => {
      const option = filterOptionsMap.get(condition.key)
      if (!option) return null
      const operation = option.validOperations.find(op => op.key === condition.operation)
      return {
        property: option,
        operation,
        value: condition.value,
        renderKey: nanoid()
      }
    }).filter(condition => condition !== null) // Filters out date range conditions
  }).filter(orConditions => orConditions.length > 0)

  return parsedQuery
}

export function parseDateRangeFromQuery (queryPayload) {
  if (!queryPayload) return { start: null, end: null }

  const startDateStr = queryPayload.find(orConditions =>
    orConditions?.[0]?.key === 'event_date' &&
    orConditions?.[0]?.operation === 'greater_than'
  )?.[0]?.value ?? null
  const endDateStr = queryPayload.find(orConditions =>
    orConditions?.[0]?.key === 'event_date' &&
    orConditions?.[0]?.operation === 'less_than'
  )?.[0]?.value ?? null

  let isRelativeToToday = false
  const parseDateValue = (dateStr) => {
    if (!dateStr) return null
    if (dateStr === 'today') {
      isRelativeToToday = true
      return new Date()
    } else if (dateStr.startsWith('last_')) {
      isRelativeToToday = true
      const daysAgo = parseInt(dateStr.split('_')[1])
      const date = new Date()
      date.setDate(date.getDate() - daysAgo)
      return date
    } else {
      const [year, month, day] = dateStr.split('-').map(Number)
      return new Date(year, month - 1, day)
    }
  }

  // Parse the strings (YYYY-MM-DD) into dates
  const startDate = parseDateValue(startDateStr)
  const endDate = parseDateValue(endDateStr)

  return { start: startDate, end: endDate, isRelativeToToday }
}
