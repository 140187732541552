<template>
  <div class="w-max">
    <div
      class="rounded-md flex items-center gap-1 px-1.5 py-1 max-h-6"
      :class="`${status.state}-container`"
    >
      <div
        v-if="status.state !== 'paused'"
        class="w-1 h-1 rounded-full"
        :class="`${status.state}-tag`"
      />
      <div v-else>
        <PauseIcon />
      </div>
      <BaseText
        class="truncate"
        type="label"
        size="xs"
      >
        {{ status.text }}
      </BaseText>
    </div>
  </div>
</template>

<script>
import PauseIcon from '../../../globals/Icons/PauseIcon.vue'
export default {
  name: 'StatusCell',
  components: {
    PauseIcon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    status () {
      const active = this.data.value.active
      const inactive = this.data.value.inactive
      const paused = this.data.value.paused
      const total = active + inactive + this.data.value.paused

      if (active !== 0) {
        return { state: 'active', text: `${active}/${total} Active` }
      }
      if (active === 0 && inactive !== 0 && paused !== 0) {
        return { state: 'inactive', text: 'Inactive' }
      }
      return { state: 'paused', text: `${paused}/${total} Paused` }
    }
  }
}
</script>

<style scoped>
.active-container{
  background-color: #EFFEFA;
  color: #184E44;
}

.paused-container{
  background-color: #FFF6E0;
  color: #603A20;
}

.active-tag {
  background-color: #00A879;
}

.inactive-tag {
  background-color: #5E6678 !important;
}

.paused-tag {
  background-color: #603A20;
}
</style>
