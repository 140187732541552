<template>
  <div class="p-3 flex items-center justify-between w-full gap-1">
    <div class="flex items-center gap-1">
      <button
        class="p-1 hover:bg-background-normal rounded-md"
        @click="navToLens"
      >
        <ChevronIcon class="transform rotate-90" />
      </button>
      <ReportIcon class="text-icon-muted" />
      <div class="flex items-center gap-2">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          {{ title }}
        </BaseText>
        <div
          v-if="loading"
          class="h-5 w-11 skeleton rounded-md"
        />
        <BaseText
          v-else
          class="text-icon-normal"
          size="sm"
        >
          {{ description }}
        </BaseText>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <div class="py-1.5 px-2">
        <!-- TODO @Sam, update after auth -->
        <div
          class="flex items-center gap-2"
        >
          <RefreshIcon class="text-icon-normal" />
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Data since 14h
          </BaseText>
        </div>
      </div>
      <!-- Share -->
      <button
        class="flex text-white items-center gap-1 black-btn-primary p-1.5 pr-3 rounded-md h-8"
      >
        <ShareIcon />
        <BaseText
          type="label"
          size="sm"
        >
          Share
        </BaseText>
      </button>
      <!-- Kebab -->

      <div
        v-on-clickaway="() => { show.optionsDropdown = false }"
        class="relative"
      >
        <button
          class="base-btn h-8 hover:bg-background-normal rounded-md transition-colors"
          :class="{'bg-background-normal' : show.optionsDropdown}"
          style="padding: 6px;"
          @click="show.optionsDropdown = !show.optionsDropdown"
        >
          <KebabMenuIcon class="text-icon-normal" />
        </button>

        <transition name="fade">
          <div
            v-if="show.optionsDropdown"
            class="p-1 absolute w-64 right-0 mt-1 border border-border-normal bg-white overflow-y-hidden dropdown-scrollable shadow-md rounded-lg "
            style="z-index: 99999"
          >
            <div
              v-for="opt in reportOptions"
              :key="opt.name"
            >
              <div
                v-if="opt.action"
                class="group cursor-pointer flex items-center gap-2 rounded-lg p-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap"
                @click="opt.action"
              >
                <component
                  :is="opt.icon"
                  class="text-icon-normal"
                />
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  {{ opt.name }}
                </BaseText>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import LensAPI from '@/api/lens'

import { mixin as clickaway } from 'vue-clickaway2'

// Icons
import KebabMenuIcon from '../../globals/Icons/KebabMenuIcon.vue'
import ShareIcon from '../../globals/Icons/ShareIcon.vue'
import ReportIcon from '../../globals/Icons/LensIcons/ReportIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import RefreshIcon from '../../globals/Icons/LensIcons/RefreshIcon.vue'
import ExportCSVIcon from '../../globals/Icons/ExportCSVIcon.vue'
import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
import DuplicateIcon from '../../globals/Icons/DuplicateIcon.vue'
export default {
  name: 'LensReportHeader',
  components: {
    ChevronIcon,
    RefreshIcon,
    ShareIcon,
    ReportIcon,
    KebabMenuIcon,
    DeleteIcon
  },
  mixins: [clickaway],
  props: {
    reportId: {
      type: String,
      default: () => ''
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    description: {
      type: String,
      default: () => ''
    }
  },
  data () {
    const reportOptions = [
      {
        icon: DuplicateIcon,
        name: 'Copy Report',
        action: this.duplicateReport
      },
      {
        icon: ExportCSVIcon,
        name: 'Export CSV',
        action: this.exportCSV
      },
      {
        icon: ExportCSVIcon,
        name: 'Export PDF',
        action: this.exportPDF
      },
      {
        icon: DeleteIcon,
        name: 'Delete Report',
        action: this.deleteReport
      }
    ]
    return {
      reportOptions,

      show: {
        optionsDropdown: false,
        duplicateModal: false,
        deleteModal: false
      }
    }
  },
  methods: {
    navToLens () {
      const currentLens = this.$route.params.lensId
      this.$router.push({ name: 'LensDashboardView', params: { lensId: currentLens } })
    },
    duplicateReport () {
      this.$showAlert({
        type: 'success',
        message: '(MOCK): Duplicated Report'
      })
    },
    exportCSV () {
      this.$showAlert({
        type: 'success',
        message: '(MOCK): export csv'
      })
    },
    exportPDF () {
      this.$showAlert({
        type: 'success',
        message: '(MOCK): mock pdf'
      })
    },
    async deleteReport () {
      console.log('%c Deleting Report', 'color: pink; font-size: 14px;')
      try {
        await LensAPI.LensReport.remove(this.reportId)
      } catch (e) {
        this.$showAlert({
          type: 'error',
          message: 'Deleting report error'
        })
      }
      this.$showAlert({
        type: 'success',
        message: 'Report deleted'
      })
      this.$router.push({ name: 'LensView' })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 150ms !important;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
