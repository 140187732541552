<template>
  <div
    v-if="header?.key"
    class="p-3 pr-1.5 h-10 flex items-center gap-x-1 justify-between group cell-header"
  >
    <!-- Active pin  -->
    <div class="flex items-center gap-1">
      <div
        v-if="isSelected"
        class="w-2.5 h-2.5 rounded-sm"
        :style="{ backgroundColor: columnColor }"
      />
      <BaseText
        type="heading"
        size="overline"
        class="text-text-subdued text-left truncate flex-shrink uppercase"
        :class="{ 'text-text-muted': header.is_pinned, 'mr-3.5': !isSelected }"
      >
        {{ header.key }}
      </BaseText>
    </div>
    <div
      v-if="!disableActions"
      class="flex items-center column-actions"
    >
      <!-- Action to pin -->
      <div
        class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
        :class="{'text-icon-muted opacity-100' : header.is_pinned}"
        @click="pinColumn(header)"
      >
        <PinIcon
          :class-name="header.is_pinned ? 'text-icon-muted' : 'text-icon-disabled'"
        />
      </div>
      <div
        ref="colHeader"
        class="hover:bg-background-normal text-icon-disabled  rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100 visual-tooltip-trigger"
        :class="{ 'text-icon-muted opacity-100': isSelected }"
        @click="selectColumn(header)"
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
      >
        <CheckmarkIcon
          :class="isSelected ? 'text-icon-muted' : 'text-icon-disabled'"
        />
      </div>
      <div
        v-on-clickaway="() => { showSortDropdown = false }"
        class="relative"
      >
        <div
          class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
          :class="{ 'text-icon-muted opacity-100': currentSort?.by === header.key || showSortDropdown, 'bg-background-normal' : showSortDropdown }"
          @click="toggleShowDropdown"
        >
          <SortColumnIcon
            :class="currentSort?.by === header.key || showSortDropdown ? 'text-icon-muted' : 'text-icon-disabled'"
            :style="{ transform: currentSort?.by === header.key && currentSort.order === 'asc' ? 'scale(1, -1)' : 'none' }"
          />
        </div>
        <transition name="fade">
          <div
            v-if="showSortDropdown"
            class="p-1 absolute right-0  mx-auto mt-1 border border-border-normal bg-white w-max shadow-md rounded-lg "
            style="z-index: 99999"
          >
            <!-- TODO @sam: add selected state (checkmark) & maybe show selected first - ask eren  -->
            <button
              class="flex items-center  gap-2 p-1.5 hover:bg-background-normal rounded-md"
              :class="{'bg-background-normal': header.key === currentSort.by && currentSort.order === 'desc'}"
              @click="sortColumn(header, 'desc')"
            >
              <SortColumnIcon />
              <BaseText size="sm">
                Sort highest to lowest
              </BaseText>
            </button>
            <button
              class="flex items-center gap-2 p-1.5 hover:bg-background-normal rounded-md"
              :class="{'bg-background-normal': header.key === currentSort.by && currentSort.order === 'asc'}"
              @click="sortColumn(header, 'asc')"
            >
              <SortColumnIcon :style="{ transform: 'scale(1, -1)' }" />
              <BaseText size="sm">
                Sort lowest to highest
              </BaseText>
            </button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'

import SortColumnIcon from '../../../globals/Icons/SortColumnIcon.vue'
import CheckmarkIcon from '../../../globals/Icons/CheckmarkIcon.vue'
import PinIcon from '../../../globals/Icons/SpyderV2Icons/PinIcon.vue'
export default {
  name: 'HeaderActionCell',
  components: {
    SortColumnIcon,
    CheckmarkIcon,
    PinIcon
  },
  mixins: [clickaway],
  props: {
    header: {
      type: Object,
      default: () => {}
    },
    isSelected: {
      type: Boolean,
      default: () => false
    },
    columnColor: {
      type: String,
      default: () => ''
    },
    currentSort: {
      type: Object,
      default: () => {}
    },
    disableActions: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      showSortDropdown: false
    }
  },
  methods: {
    showTooltip () {
      const reference = this.$refs.colHeader

      // Contains all the tooltips for column headers
      const tooltipContainer = this.$parent.$refs.tooltipContainer
      const includeTooltip = tooltipContainer.$refs.includeTooltip
      includeTooltip.style.display = 'block'

      if (includeTooltip && reference) {
        const tooltipRect = includeTooltip.getBoundingClientRect()
        const referenceRect = reference.getBoundingClientRect()
        includeTooltip.style.top = `${referenceRect.top - tooltipRect.height - 12}px`
        includeTooltip.style.left = `${referenceRect.left + (referenceRect.width / 2) - (tooltipRect.width / 2)}px`
        includeTooltip.style.opacity = '1'
        includeTooltip.style.transition = 'opacity 150ms ease'
      }
    },
    hideTooltip () {
      const tooltipContainer = this.$parent.$refs.tooltipContainer
      const includeTooltip = tooltipContainer.$refs.includeTooltip
      if (includeTooltip) {
        includeTooltip.style.opacity = '0'
        includeTooltip.style.transition = 'opacity 150ms ease'
      }
    },
    toggleShowDropdown () {
      this.showSortDropdown = !this.showSortDropdown
    },
    selectColumn (header) {
      this.$emit('select', header)
    },
    pinColumn (header) {
      this.$emit('pin', header)
    },
    sortColumn (header, dir) {
      this.$emit('sort', header, dir)
      this.showSortDropdown = false
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 150ms;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
}
</style>
