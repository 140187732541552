<template>
  <div class="graph-section bg-neutral-25">
    <!-- SELECTED KPIS AND GRAPH TYPE SELECTOR -->
    <div class="w-full flex items-center gap-1.5 p-2">
      <!-- Selected KPIs list -->
      <div v-for="(kpi, index) in selectedKpis" :key="`selected-kpi-${index}`" class="selected-kpi">
        <!-- color indicator -->
        <svg v-if="showMetricColors" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect x="7" y="7" width="10" height="10" rx="2" :fill="getKpiColor(index)" />
        </svg>
        <!-- KPI name -->
        <BaseText type="label" size="sm" class="text-text-muted capitalize" :class="{'pl-1.5': !showMetricColors}">
          {{ getMetricName(kpi) }}
        </BaseText>
        <!-- Remove button -->
        <button class="group w-6 h-6 flex items-center justify-center"
        @click="removeMetric(kpi)">
          <TagRemoveIcon :width="16" class="text-text-subdued transition-colors duration-100 group-hover:text-icon-muted" />
        </button>
      </div>
      <!-- Add Metric Button -->
      <div class="relative" v-on-clickaway="() => { showAddMetricDropdown = false }">
        <button ref="addMetricBtn" class="group flex items-center p-0.5 rounded-md transition-colors"
        :class="addMetricClasses.btn" :disabled="!canAddKpis" 
        @click="showAddMetricDropdown = !showAddMetricDropdown">
          <div class="w-6 h-6 flex items-center justify-center">
            <PlusIcon :width="16" :height="16" class="transition-colors"
            :class="addMetricClasses.icon" />
          </div>
          <BaseText type="label" size="sm" class="pl-0.5 py-0.5 pr-2">
            Add Metric
          </BaseText>
        </button>
        <!-- Add Metric Dropdown -->
        <transition 
          name="add-metric" 
          :leave-active-class="disableAddMetricFadeOut ? '' : 'add-metric-leave-active'"
          :leave-to-class="disableAddMetricFadeOut ? '' : 'add-metric-leave-to'"
        >
          <div v-if="showAddMetricDropdown" class="add-metric-dropdown">
            <PropertySelectDropdown 
              search-placeholder="Search Metrics"
              metrics-only
              :selected-metrics="selectedKpis"
              @optionSelected="handleAddMetric"
              @close="showAddMetricDropdown = false"
            />
          </div>
        </transition>
      </div>
      <!-- Graph Type Selector -->
      <div class="ml-auto flex items-center gap-0.5 p-0.5 rounded-md bg-neutral-50">
        <button class="graph-selector-atom" :class="{'active': graphType === 'bar'}" @click="changeGraphType('bar')">
          <BarGraphIcon class="text-icon-normal" />
        </button>
        <!-- <button class="graph-selector-atom" :class="{'active': graphType === 'line'}" @click="changeGraphType('line')">
          <LineGraphIcon class="text-icon-normal" />
        </button> -->
        <button class="graph-selector-atom" :class="{'active': graphType === 'grid'}" @click="changeGraphType('grid')">
          <GridGraphIcon class="text-icon-normal" />
        </button>
      </div>
    </div>
    <!-- GRAPH AREA -->
    <div ref="graphArea" class="w-full h-full">
      <LensBarGraphs 
        v-if="graphType === 'bar'" 
        :data-items="dataItems" 
        :data-summary="dataSummary"
        :selected-kpis="selectedKpis" 
      />
      <div v-else-if="graphType === 'line'" class="w-full flex items-center" style="height: 360px">
        <!-- PLACEHOLDER -->
        <BaseText type="label" size="sm" class="text-text-muted w-full text-center">
          Included in future release
        </BaseText>
      </div>
      <LensGridGraphs 
        v-else-if="graphType === 'grid'" 
        :data-items="dataItems"
        :data-summary="dataSummary"
        :selected-kpis="selectedKpis" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import kpiColors from '../../../utils/lens/selectedMetricColors'
import { mixin as clickaway } from 'vue-clickaway2'

// Components
import LensBarGraphs from './bar/LensBarGraphs.vue'
import LensGridGraphs from './grid/LensGridGraphs.vue'
import PropertySelectDropdown from '../filters/PropertySelectDropdown.vue'

// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
import BarGraphIcon from '../../globals/Icons/LensIcons/graphs/BarGraphIcon.vue'
import LineGraphIcon from '../../globals/Icons/LensIcons/graphs/LineGraphIcon.vue'
import GridGraphIcon from '../../globals/Icons/LensIcons/graphs/GridGraphIcon.vue'

export default {
  name: 'LensReportGraph',
  mixins: [clickaway],
  components: {
    LensBarGraphs,
    LensGridGraphs,
    PropertySelectDropdown,
    TagRemoveIcon,
    PlusIcon,
    BarGraphIcon,
    LineGraphIcon,
    GridGraphIcon
  },
  props: {
    graphType: {
      type: String,
      default: 'bar'
    },
    dataItems: {
      type: Array,
      default: () => []
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    canAddKpis: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showAddMetricDropdown: false,
      disableAddMetricFadeOut: false
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    showMetricColors () {
      return this.graphType !== 'grid'
    },
    addMetricClasses () {
      if (this.canAddKpis) {
        if (this.showAddMetricDropdown) 
          return { 
            btn: 'bg-neutral-50 text-text-muted', 
            icon: 'text-icon-muted' 
          }
        else 
          return { 
            btn: 'hover:bg-neutral-50 text-text-muted duration-100', 
            icon: 'text-icon-normal group-hover:text-icon-muted duration-100' 
          }
      } else {
        return { 
          btn: 'text-text-disabled cursor-default duration-300', 
          icon: 'text-icon-disabled duration-300' 
        }
      }
    }
  },
  methods: {
    changeGraphType (type) {
      this.$emit('update:graphType', type)
    },
    handleAddMetric (key) {
      this.pausePointerEvents('graphArea')
      this.disableAddMetricFadeOut = true
      this.$nextTick(() => {
        this.showAddMetricDropdown = false
        this.$emit('updateSelectedKpis', [...this.selectedKpis, key])
        this.$nextTick(() => { this.disableAddMetricFadeOut = false })
      })
    },
    removeMetric (key) {
      const updatedKpis = this.selectedKpis.filter(kpi => kpi !== key)
      this.pausePointerEvents('addMetricBtn')
      this.$emit('updateSelectedKpis', updatedKpis)
    },
    getKpiColor (index) {
      return kpiColors[index]
    },
    getMetricName (kpi) {
      return this.getMetricLookup?.[kpi]?.name || kpi
    },
    pausePointerEvents (elementRef) {
      // Pause pointer events for the add metric btn to prevent jarring animations when removing right-most metric
      const element = this.$refs[elementRef]
      if (!element) return

      element.style.pointerEvents = 'none'
      let initialMouseX, initialMouseY
      // Reenable events after 4px of mouse movement
      const trackMouseMovement = (event) => {
        if (!initialMouseX || !initialMouseY) {
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          return
        }
        const deltaX = Math.abs(event.clientX - initialMouseX)
        const deltaY = Math.abs(event.clientY - initialMouseY)
        if (deltaX >= 4 || deltaY >= 4) {
          element.style.pointerEvents = 'auto'
          window.removeEventListener('mousemove', trackMouseMovement)
        }
      }
      window.addEventListener('mousemove', trackMouseMovement)
    }
  }
}
</script>

<style scoped>
.graph-section {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  padding: 4px;
}
.selected-kpi {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}
.graph-selector-atom {
  padding: 2px;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: none;
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
}
.graph-selector-atom:hover {
  background-color: #F6F8FA; /* neutral-25 */
}
.graph-selector-atom.active, .graph-selector-atom.active:hover {
  background-color: white;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0.25px 1.5px 0.75px rgba(6, 7, 16, 0.02);
}
.add-metric-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 500;
}

.add-metric-enter-active, .add-metric-leave-active {
  transition: opacity 100ms ease-in-out;
}
.add-metric-enter-from, .add-metric-enter, .add-metric-leave-to {
  opacity: 0;
}
.add-metric-enter-to, .add-metric-leave-from {
  opacity: 1;
}
</style>
