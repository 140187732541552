<template>
  <MainLayout
    v-scroll="onScroll"
    bg-color="white"
    no-content-padding
    scroll-toolbar
  >
    <template #toolbar>
      <LensReportHeader
        :report-id="report?.id ?? $route.params.reportId"
        :title="report?.name"
        :description="report?.description"
        :loading="loading.report"
      />
    </template>
    <template #filter>
      <div
        ref="filterBar"
        class="border border-border-normal bg-white bg-opacity-90"
        style="backdrop-filter: blur(6px);"
      >
        <LensFilterToolbar
          :group-by="report?.group_by"
          :applied-filters.sync="appliedFilters"
          :selected-date-range.sync="selectedDateRange"
          @update:groupBy="handleReportChange"
          @fetchGroups="fetchAdGroups($event)"
        />
      </div>
    </template>
    <template #content>
      <div class="bg-white">
        <div class="px-3 pt-4">
          <LensReportGraph
            :graph-type.sync="graphType"
            :data-items="graphDataItems"
            :data-summary="graphDataSummary"
            :selected-kpis="selectedColumns[graphType]"
            :can-add-kpis="!isSelectedColsMaxed"
            @updateSelectedKpis="updateSelectedColumns"
          />
        </div>
        <div class="px-3 pt-3">
          <!-- Table settings btn -->
          <LensTableOptions
            :loading="loading?.report"
            :table-pagination="getPagination"
            :table-columns="report?.table_columns"
            :table-config="report?.table_config"
            @paginationChanged="handlePaginationChange"
            @reportChanged="handleReportChange"
          />
          <Transition
            name="tableFade"
            mode="out-in"
          >
            <div
              v-if="loading.report"
              class="grid grid-cols-12 grid-rows-6 gap-0 border relative border-neutral-50 mr-3 mt-3 rounded-lg"
            >
              <!-- Loading text-->
              <div class="absolute w-full h-full flex flex-col items-center justify-center gap-1 text-center">
                <div>
                  <BaseLoadingSpinnerCircle
                    small
                    :width="24"
                    :height="24"
                    class="text-text-muted"
                  />
                </div>
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  Loading Data...
                </BaseText>
                <BaseText
                  class="text-text-normal w-64"
                  size="sm"
                >
                  One moment while we load the data. This might take up to a minute.
                </BaseText>
              </div>
              <!-- Grid background -->
              <div
                v-for="index in 36"
                :key="index"
                :class="{
                  'border-b': index <= 30,
                  'border-r': index % 30 !== 0,
                }"
                class="border-neutral-50 h-20 col-span-2"
              />
            </div>

            <LensMetricTable
              v-else
              class="mt-4"
              :grouped-by="report?.group_by"
              :table-pagination="getPagination"

              :loading="loading.adGroups"

              :included-kpis="getAppliedPreset?.table_columns"
              :table-columns="report?.table_columns"

              :graph-info="graphInfo"

              :selected-columns="selectedColumns[graphType]"
              :selected-rows.sync="selectedRows"
              :max-row-count="totalAllowedRows"

              :table-config="report?.table_config"
              :current-sort="report?.sorted_column"
              :data="tableResults"

              @update:selectedColumns="updateSelectedColumns"
              @update:selectedRows="updateSelectedRows"

              @paginationChanged="handlePaginationChange"
              @reportChanged="handleReportChange"
            />
          </Transition>
        </div>

        <LensSettingsDrawer
          v-if="getColumnDrawerView"
          :table-columns="report?.table_columns"
          @reportChanged="handleReportChange"
          @close="closeColumnDrawer"
        />
      </div>
      <!-- Modals -->
      <EditPresetModal
        v-if="showCreatePreset"
        key="new-preset"
        is-new
        @close="showCreatePreset = false"
        @save="handleNewPreset"
      />
      <!-- Changes prompt -->
      <ReportEditSavePrompt
        :loading-info="editLoading"
        :original-report="originalReport"
        :changed-report="report"
        @save="handleReportUpdate"
        @cancel="handleReportCancel"
      />
    </template>
  </MainLayout>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import _ from 'lodash'
import LensAPI from '@/api/lens'
import {
  createFilterQuery,
  parseFiltersFromQuery,
  parseDateRangeFromQuery
} from '@/utils/lens/filters/filterQueryUtils'

// Components
import MainLayout from '../../layouts/MainLayout.vue'
import LensFilterToolbar from '../../components/lens/filters/LensFilterToolbar.vue'
import LensMetricTable from '../../components/lens/table/LensMetricTable.vue'
import LensSettingsDrawer from '../../components/settings/LensSettingsDrawer.vue'
import LensTableOptions from '../../components/lens/table/LensTableOptions.vue'
import ReportEditSavePrompt from '../../components/lens/report/ReportEditSavePrompt.vue'
import LensReportGraph from '../../components/lens/graphs/LensReportGraph.vue'
import LensReportHeader from '../../components/lens/report/LensReportHeader.vue'
import EditPresetModal from '../../components/lens/settings/EditPresetModal.vue'

export default {
  name: 'LensReportView',
  components: {
    MainLayout,
    LensFilterToolbar,
    LensMetricTable,
    LensSettingsDrawer,
    LensReportGraph,
    LensTableOptions,
    EditPresetModal,
    ReportEditSavePrompt,
    LensReportHeader
  },
  mixins: [clickaway],
  data () {
    const groupOptions = [
      { name: 'Ad Name', value: 'ad_name' },
      { name: 'Ad Copy', value: 'ad_copy' },
      { name: 'Landing Page', value: 'destination_url' },
      { name: 'Creative', value: 'creative' }
    ]

    const MAX_GRAPH_ROWS = {
      bar: 8,
      line: 8,
      grid: 12
    }
    return {
      MAX_GRAPH_ROWS,

      groupOptions,

      appliedGroup: groupOptions[0],

      graphType: 'bar',
      selectedColumns: {
        bar: [],
        line: [],
        grid: []
      },

      selectedRows: [],

      showDropdown: {
        tableSettings: false,
        group: false
      },
      showColumnDrawer: false,
      showCreatePreset: false,

      // Table
      tableResults: [],

      // Lens
      lensId: null,
      // Report
      wasCustom: false,
      originalReport: {},
      report: {},
      reportNotFound: false,

      // Query Filters
      appliedFilters: [],
      selectedDateRange: {
        start: null,
        end: null,
        isRelativeToToday: false
      },

      // Update type with error or success to enable ReportEditSavePrompt with ui
      editLoading: {
        type: null,
        value: false
      },

      loading: {
        report: true,
        adGroups: false
      },

      hasMounted: false
    }
  },
  watch: {
    graphType (type) {
      const rowCount = this.report?.selected_graph_rows[type]
      this.selectedRows = this.tableResults.ad_groups.slice(0, rowCount)
    },
    appliedFilters (filters) {
      this.handleFiltersChange(filters, this.selectedDateRange)
    },
    selectedDateRange (dateRange) {
      this.handleFiltersChange(this.appliedFilters, dateRange)
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getAppliedPreset', 'getPresets', 'getColumnDrawerView', 'getPagination']),
    graphInfo () {
      return {
        selectedGraph: this.graphType,
        data: {
          bar: {
            selected: this.report?.selected_columns?.bar?.length || 0,
            total: 4
          },
          line: {
            selected: this.report?.selected_columns?.line?.length || 0,
            total: 2
          },
          grid: {
            selected: this.report?.selected_columns?.grid?.length || 0,
            total: 12
          }
        }
      }
    },
    graphDataItems () {
      return this.selectedRows
    },
    graphDataSummary () {
      return {
        ...this.tableResults.summary_stats,
        startDate: this.selectedDateRange.start,
        endDate: this.selectedDateRange.end
      }
    },
    reportRowCount () {
      return this.report?.selected_graph_rows[this.graphType]
    },
    totalAllowedRows () {
      return this.MAX_GRAPH_ROWS?.[this.graphType] ?? 8
    },
    isSelectedColsMaxed () {
      const currLength = this.selectedColumns[this.graphType].length
      return currLength >= this.graphInfo.data[this.graphType].total
    }
  },
  async mounted () {
    // Set initial top bar styles
    this.$nextTick(() => {
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.position = 'sticky'
        this.$refs.filterBar.style.top = '16px'
        this.$refs.filterBar.style.zIndex = '40000'
      }
    })
    await this.fetchPresets()

    const reportId = this.$route.params.reportId
    this.lensId = this.$route.params.lensId
    await this.fetchReport(reportId)
    if (this.reportNotFound) {
      this.$showAlert({ type: 'error', message: 'Report not found' })
      return
    }
    await this.fetchAdGroups()
    this.loading.report = false
    this.hasMounted = true
  },
  methods: {
    ...mapActions('LensModule', ['fetchPresets', 'updateColorFormat', 'fetchAdGroups', 'setPresetById', 'updateIsCustomPreset', 'updatePagination']),
    ...mapMutations('LensModule', ['SET_COLUMN_DRAWER_VIEW']),
    async fetchAdGroups (query) {
      this.loading.adGroups = true
      const report = this.report
      const page = query?.page

      const group = query?.group_by ?? this.report?.group_by
      const sort = query?.sort ?? this.report?.sorted_column
      const currentFilter = query?.filters ?? report.filters
      const currentPage = page?.currentPage ?? (this.getPagination?.currentPage || 1)
      const limit = page?.limit ?? (this.getPagination?.limit || 10)
      const payload = {
        group,
        filters: currentFilter,
        page: currentPage,
        limit,
        sort
      }
      const res = await LensAPI.AdGroups.getLensGroups(this.lensId, payload)

      this.updatePagination({ ...this.getPagination, totalPages: res.pages, totalItems: res.total })

      this.tableResults = res.data

      // Prevents setting rows on pagination/query changes
      if (!this.hasMounted || !(query?.page || query?.limit) || query?.sort) {
        const rowCount = this.report?.selected_graph_rows[this.graphType]
        this.updateSelectedRows(res.data.ad_groups.slice(0, rowCount))
      }
      this.loading.adGroups = false
    },
    closeColumnDrawer () {
      this.SET_COLUMN_DRAWER_VIEW(false)
    },
    async fetchReport (id) {
      const res = await LensAPI.LensReport.getByID(id)
      const data = res.data
      if (_.isEmpty(data)) {
        this.reportNotFound = true
        return
      }
      const dataTableConfig = data.table_config

      // presetId should be null if column hashes dont match on fetch. Indicating a custom report
      const isColHashEqual = data.preset?.columns_hash === data.columns_hash
      const presetId = isColHashEqual ? data.preset?.id : null
      console.log('%c FETCHING REPORT', 'font-size:18px')
      if (presetId) {
        this.updateIsCustomPreset(false)
        this.setPresetById({ id: presetId, shouldSelect: true })
      } else this.updateIsCustomPreset(true)
      this.wasCustom = !presetId
      // Object should only contain acceptable fields
      const tableConfig = {
        ...dataTableConfig,
        colorFormat: dataTableConfig?.colorFormat || '',
        showStatus: dataTableConfig?.showStatus || false,
        showTags: dataTableConfig?.showTags || false
      }
      this.updateColorFormat(tableConfig.colorFormat)
      const reportData = {
        name: data?.name,
        description: data?.description,
        group_by: data?.group_by,
        filters: data?.filters,
        selected_columns: data?.selected_columns,
        selected_graph_rows: data?.selected_graph_rows,
        table_columns: data?.table_columns,
        preset_id: presetId,
        columns_hash: data?.columns_hash,
        sorted_column: data?.sorted_column || { by: 'spend', order: 'desc' },
        table_config: tableConfig
      }

      this.originalReport = _.cloneDeep(reportData)
      this.report = reportData
      this.appliedFilters = parseFiltersFromQuery(data?.filters)
      this.selectedDateRange = parseDateRangeFromQuery(data?.filters)
      // Initalize selected rows & columns (rows must be initalized based on query data)
      this.selectedColumns = { ...data?.selected_columns }
      // this.updateSelectedColumns(this.report.selected_columns[this.graphType])
    },
    async handleNewPreset (preset) {
      try {
        const newPreset = {
          ...preset,
          table_columns: []
        }
        await LensAPI.LensPreset.create(newPreset)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
      await this.fetchPresets()
    },
    handleFiltersChange (filters, dateRange) {
      if (!this.hasMounted) return
      const filterQuery = createFilterQuery(filters, dateRange)
      this.report.filters = filterQuery
      this.fetchAdGroups({ filters: filterQuery })
    },
    // Report actions
    handleReportChange (change) {
      console.log(`%c Updating ${change.type}`, 'color: pink; font-size: 18px;')
      const { type, value, preventFetch } = change
      this.report = {
        ...this.report,
        [type]: value
      }
      if (!preventFetch) {
        this.fetchAdGroups({
          ...(type === 'sorted_column' ? this.report.sorted_column : null)
        })
      }
    },
    handlePaginationChange (change) {
      console.log('%c Updating Pagination', 'color: pink; font-size: 18px;')
      const { type, value, preventFetch } = change

      // If no type specified, spread into object parents
      const newPagination = type ? {
        ...this.getPagination,
        [type]: value
      } : {
        ...this.getPagination,
        ...value
      }
      this.updatePagination(newPagination)
      if (!preventFetch) {
        this.fetchAdGroups({ page: value?.currentPage, limit: value?.limit })
      }
    },
    async handleReportUpdate (toUpdate) {
      console.log('%c SAVING REPORT', 'color: orange; font-size: 18px')
      // Init async loading state
      this.editLoading = {
        type: null,
        value: true
      }
      try {
        const reportId = this.report?.id || this.$route.params?.reportId
        console.log('SAVING', toUpdate)
        await LensAPI.LensReport.update(reportId, toUpdate)

        // Show success message & wait before dismiss & refetch
        this.editLoading = {
          type: 'success',
          value: false
        }

        // TODO @Sam: handle spam
        setTimeout(async () => {
          this.editLoading = {
            type: null,
            value: false
          }
        }, 1500)
        this.originalReport = _.cloneDeep(this.report)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
        this.editLoading = {
          type: null,
          value: false
        }
      }
    },
    updateEditLoad (type, value) {
      this.editLoading = {
        type,
        value
      }
    },
    async handleReportCancel () {
      // Reset changed report to original
      this.report = _.cloneDeep(this.originalReport)
      this.updateColorFormat(this.originalReport?.table_config?.colorFormat || '')

      // Reset cols to original report, and rows to first X (rowCount) rows
      const selectedColumns = this.originalReport.selected_columns
      this.selectedColumns = { ...selectedColumns }

      const rowCount = this.originalReport?.selected_graph_rows[this.graphType]
      this.selectedRows = this.tableResults.ad_groups.slice(0, rowCount)

      const originalFilters = this.originalReport?.filters

      const presetId = this.originalReport.preset_id
      if (presetId) this.setPresetById({ id: presetId, shouldSelect: true })
      this.updateIsCustomPreset(this.wasCustom)
      this.appliedFilters = originalFilters ? parseFiltersFromQuery(originalFilters) : []
      this.selectedDateRange = originalFilters ? parseDateRangeFromQuery(originalFilters) : {
        start: null,
        end: null
      }
    },
    updateSelectedRows (rows) {
      this.selectedRows = rows

      const rowLength = rows.length
      // Consistent row selection until better ux is determined
      this.report.selected_graph_rows[this.graphType] = rowLength
    },
    updateSelectedColumns (columns) {
      // We need to check if we're adding new columns to the report
      // TODO: Might want to block this func all together if table_columns is undefined

      const existingTableColumns = new Set(this.report?.table_columns?.map(col => col.key) || [])
      const newTableColumns = [...columns].filter(column => !existingTableColumns.has(column))
      if (newTableColumns.length) {
        // If there are new columns, we need to update the table_columns state to include them
        const change = {
          type: 'table_columns',
          value: [
            ...this.report?.table_columns || [], 
            ...newTableColumns.map(col => ({ key: col, is_pinned: false }))
          ],
          preventFetch: true
        }
        this.handleReportChange(change)
        this.updateIsCustomPreset(true) // Flag the preset as custom
      }

      // Update selected columns
      this.selectedColumns[this.graphType] = columns
      this.report.selected_columns[this.graphType] = [...columns]
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.marginLeft = `${marginValue}px`
        this.$refs.filterBar.style.marginRight = `${marginValue}px`
        this.$refs.filterBar.style.marginTop = `${marginValue}px`
        this.$refs.filterBar.style.borderRadius = `${rounding}px`
      }
      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop && this.$refs.filterBar) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        15
      )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        7
      )}px ${shadowSection(
        10
      )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        7
      )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        3
      )}px ${shadowSection(5)}px`
      // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        if (this.$refs.filterBar) {
          this.$refs.filterBar.style.boxShadow = null
        }
      }
    }
  }
}
</script>

<style scoped>
  .dropdown-scrollable {
    transition: colors 250ms ease-in-out;
    top: 100%;
    z-index: 999;
  }
  .dropdown-scrollable::-webkit-scrollbar {
    width: 3px;
  }
  .dropdown-scrollable::-webkit-scrollbar-thumb {
    background-color: #DFE1E7;
    border-radius: 18px;
  }

  .grid-background{
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #ECEFF3 1px, transparent 1px),
    linear-gradient(to bottom, #ECEFF3 1px, transparent 1px);
  background-size: 124px 82px;
  }

  .tableFade-enter-active,
  .tableFade-leave-active {
    transition: all 0.25s ease-out;
  }

  .tableFade-enter-from {
    opacity: 0;
    transform: translateY(1rem);
  }

  .tableFade-leave-to {
    opacity: 0;
    transform: translateY(-1rem);
  }
</style>
