<template>
  <div class="flex flex-col gap-5 mt-8">
    <div class="flex items-center gap-2 p-1.5">
      <MetaMetricsIcon class="flex-shrink-0 text-icon-disabled" />
      <BaseText
        class="text-text-subdued flex-shrink-0 uppercase"
        type="heading"
        size="overline"
      >
        All Meta Metrics
      </BaseText>
      <div class="w-full border-t border-border-normal" />
    </div>
    <div
      v-if="Object.keys(getMetrics).length > 0"
      class="relative metric-container"
    >
      <div
        v-for="(metricSection) in Object.keys(getMetrics)"
        :key="metricSection"
      >
        <MetricSectionList
          :ref="metricSection"
          :section-icon="metricIcons?.[metricSection]"
          :section-id="metricSection"
          :data="getMetrics[metricSection]"
          :preset-list="tableColumns"
          :fuzzy-results="getFuzzyResults"
          @handleHover="addHover"
          @add="handleMetricAdd"
        />
        <div class="w-full border-t border-border-normal my-8" />
      </div>
    </div>
    <!-- Tooltip -->
    <transition name="left-fade">
      <div
        v-if="tooltipHovered?.key"
        ref="tooltipElem"
        class="label-tooltip"
      >
        <div class="label-tooltip-content p-1 flex flex-col gap-3">
          <div class="p-2">
            <BaseText
              type="label"
              size="sm"
              class="text-white"
            >
              {{ tooltipHovered.name }}
            </BaseText>
            <BaseText
              class="text-white opacity-70"
              type="body"
              size="sm"
            >
              {{ tooltipHovered.description }}
            </BaseText>
          </div>
          <div
            v-if="tooltipHovered?.formula"
            class="bg-neutral-alpha-50 rounded-md p-1 uppercase"
          >
            <div class="flex items-center gap-1 px-1 py-0.5">
              <FormulaIcon
                class="text-white"
                alpha="0.68"
              />
              <BaseText
                type="heading"
                size="overline"
                class="text-white"
              >
                Formula
              </BaseText>
            </div>
            <div>
              <MetricFormulaRenderer :ast="tooltipHovered.formula" />
            </div>
          </div>
          <div
            v-if="tooltipHovered?.note"
            class="text-secondary-yellow-25 tooltip-note  p-1 space-y-1"
          >
            <div class="flex items-center gap-1">
              <NoteIcon />
              <BaseText
                type="heading"
                size="overline"
              >
                Note
              </BaseText>
            </div>
            <div class="tooltip-note-body p-2 rounded-md">
              <BaseText
                size="sm"
                class="whitespace-pre-wrap"
              >
                {{ tooltipHovered.note }}
              </BaseText>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { animate, easeOut } from 'popmotion'
import _ from 'lodash'
// Components
import MetricSectionList from '../MetricSectionList.vue'
// Icons
import MetaMetricsIcon from '../../../globals/Icons/LensIcons/MetaMetricsIcon.vue'
import FormulaIcon from '../../../globals/Icons/LensIcons/MetricIcons/FormulaIcon.vue'
import NoteIcon from '../../../globals/Icons/LensIcons/MetricIcons/NoteIcon.vue'
import MetricFormulaRenderer from '../../MetricFormulaRenderer.vue'
import sectionIcons from '../../../../utils/lens/metricIconMap'

export default {
  name: 'MetaMetricsSettings',
  components: {
    MetaMetricsIcon,
    MetricSectionList,
    FormulaIcon,
    NoteIcon,
    MetricFormulaRenderer
  },
  props: {
    preset: {
      type: Object,
      default: () => {}
    },
    columns: {
      type: Array,
      default: () => []
    },
    hasSelectedPreset: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const metricIcons = sectionIcons
    return {
      metricIcons,
      tooltipHovered: null,
      lastHover: {
        timestamp: null,
        elem: null
      },
      debounceUnHover: null,

      // Metrics settings correlate to presets & custom columns. customPresetCols manages the local column state
      customPresetCols: []

    }
  },
  computed: {
    ...mapGetters('LensModule', ['getIsCustomPreset']),
    ...mapGetters('MetricsModule', ['getFuzzyResults', 'getMetrics', 'getMetricSearch']),
    tableColumns () {
      const isCustom = this.getIsCustomPreset
      return (!this.hasSelectedPreset && isCustom) ? this.customPresetCols : this.preset.table_columns
    }
  },
  watch: {
    // Handles tooltip tweening
    tooltipHovered (newElem, oldElem) {
      if (newElem) {
        this.$nextTick(() => {
          const hoveredElement = document.querySelector(`#${newElem.key}`)
          const clientRect = hoveredElement.getBoundingClientRect()
          const tooltip = this.$refs.tooltipElem
          const halfTooltipHeight = clientRect.height / 2
          const newTop = clientRect.top - 80 - halfTooltipHeight

          // Handle init
          if (!oldElem) {
            tooltip.style.left = `${clientRect.right}px`
            tooltip.style.top = `${newTop}px`
            return
          }
          const prevHoveredElement = document.querySelector(`#${oldElem.key}`)
          const oldClientRect = prevHoveredElement.getBoundingClientRect()

          // Account for 80px drawer padding
          const oldTop = oldClientRect.top - 80 - halfTooltipHeight
          animate({
            from: oldTop,
            to: newTop,
            ease: easeOut,
            duration: 100,
            onUpdate: (value) => {
              tooltip.style.top = `${value}px`
            }
          })
          tooltip.style.left = `${clientRect.right}px`
        })
      }
    }
  },
  mounted () {
    this.debounceUnHover = _.debounce(this.resetLasthover, 100)
    this.customPresetCols = this.columns.map((col) => col.key)
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField']),
    resetLasthover () {
      if (!this.lastHover.elem) {
        this.lasthover = null
        this.tooltipHovered = null
      }
    },
    addHover (metric) {
      if (!metric) {
        this.lastHover = {
          timestamp: Date.now(),
          elem: metric
        }
        this.debounceUnHover()
        return
      }
      this.tooltipHovered = metric
      this.lastHover.elem = metric
    },
    manageListOperation (metricKey) {
      let newMetricValue = [...this.tableColumns]

      if (newMetricValue.includes(metricKey)) {
        newMetricValue = newMetricValue.filter(key => key !== metricKey)
      } else {
        newMetricValue.push(metricKey)
      }

      return newMetricValue
    },
    handleMetricAdd (metricKey, sectionKey) {
      // Update later
      if (!this.tableColumns) {
        this.$showAlert({ type: 'error', message: 'Select a preset before editing' })
        return
      }
      const newKpiOrder = this.manageListOperation(metricKey)
      this.customPresetCols = newKpiOrder
      this.$emit('updateCols', this.customPresetCols)
      // Also updated seleckted KPIs
      this.updateLocalPresetField({
        key: 'table_columns',
        value: newKpiOrder
      })
    }
  }
}
</script>

<style scoped>
.left-fade-enter-active,
.left-fade-leave-active {
  transition: opacity 100ms;
}

.left-fade-enter-from, .left-fade-enter, .left-fade-leave-to {
  opacity: 0;
}
.left-fade-enter-to, .left-fade-leave-from {
  opacity: 1;
}

.label-tooltip {
  position: fixed;
  margin-top: auto;
  margin-bottom: auto;
  width: 290px;
  z-index: 9999;
}
.label-tooltip-content{
    background: rgba(6, 7, 15, 0.92);
    border-radius: 12px;
}

.tooltip-note{
  border-radius: 6px;
  background: rgba(255,190,76,0.08);
}
.tooltip-note-body{
  background: rgba(255,190,76,0.08);
}
</style>
