import store from '../../store'

const getMetricLookup = store.getters['MetricsModule/getMetricLookup']
const positiveCells = ['#EFFEFA', '#D8F3EE', '#B9E9E0']
const negativeCells = ['#FFF0F3', '#FDD9E0', '#F9C8D2']

const getRelativeMetricScoreColor = (percentDiff, kpi, outlierFormat, summaryStats) => {
  const { max, min } = summaryStats.ad_groups?.percent_deviation

  const highestAvg = Math.floor(max[kpi])
  const lowestAvg = Math.floor(min[kpi])

  // Will need to update to handle arbitrary # of columns
  if (kpi === 'status') return
  const metricDir = getMetricLookup[kpi].metric_direction
  const avgToDivide = metricDir === 'positive' ? highestAvg : lowestAvg

  const cellColors = outlierFormat === 'positive' ? positiveCells : negativeCells
  const divider = Math.floor(avgToDivide / cellColors.length)

  let scoreColor = ''
  let direction = ''
  if ((outlierFormat === 'positive_negative' || outlierFormat === 'positive') && percentDiff >= 5) {
    const greenIntensity = highestAvg === percentDiff ? cellColors?.length - 1 : cellColors.findIndex((_, i) => percentDiff >= i * divider && percentDiff < (i + 1) * divider)
    scoreColor = greenIntensity !== -1 ? positiveCells[greenIntensity] : ''
    direction = 'positive'
  }
  else if ((outlierFormat === 'positive_negative' || outlierFormat === 'negative') && percentDiff <= -5) {
    const redIntensity = lowestAvg === percentDiff ? -1 : cellColors.findIndex((_, i) => percentDiff <= ((i + 1) * divider && percentDiff) <= i * divider)
    scoreColor = redIntensity !== -1 ? cellColors[redIntensity] : ''
    direction = 'negative'
  }

  return { scoreColor, direction }
}

export {
  getRelativeMetricScoreColor
}