<template>
  <div class="grid-graph-container w-full">
    <div v-for="item in dataItems" :key="`grid-card-${item.group_by}`" class="grid-graph-item">
      <div class="flex-grow mx-auto h-full" style="max-width: 410px">
        <GridGraphCard 
          :data="item" 
          :data-summary="dataSummary"
          :selected-kpis="selectedKpis" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import GridGraphCard from './GridGraphCard.vue'
export default {
  name: 'LensGridGraphs',
  components: {
    GridGraphCard
  },
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.grid-graph-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 4px;
}
.grid-graph-item {
  flex: 1 1 275px;
  justify-content: center;
}
</style>