<template>
  <div class="mt-3 flex items-center justify-between">
    <div class="flex items-center gap-3">
      <!-- Pagination dropdown -->
      <div
        v-on-clickaway="() => { showPaginationPopover = false }"
        class="relative"
        @click="showPaginationPopover = !showPaginationPopover"
      >
        <div
          class="flex gap-2 items-center p-2 py-1.5 rounded-md bg-white hover:bg-background-normal border border-border-normal cursor-pointer transition-colors"
          :class="`${showPaginationPopover && 'bg-background-normal'}`"
        >
          <BaseText>{{ `${pagination.limit} results` || 'Select an option' }}</BaseText>
          <div
            class="transform transition-transform duration-200"
            :style="showPaginationPopover ? 'transform: scale(1, -1)' : ''"
          >
            <ChevronIcon class-name="text-icon-disabled" />
          </div>
        </div>
        <transition name="fade">
          <div
            v-if="showPaginationPopover"
            class="absolute w-full min-w-max bg-white right-0 p-1 overflow-y-auto max-h-72 dropdown-scrollable  shadow-md rounded-lg flex flex-col gap-2"
          >
            <button
              v-for="option in paginationOptions"
              :key="option.name"
              class="w-full bg-white hover:bg-background-normal rounded-md py-1 px-2 text-left"
              @click="updateResultLimit(option)"
            >
              <BaseText
                size="sm"
              >
                {{ option }} Results
              </BaseText>
            </button>
          </div>
        </transition>
      </div>
      <div>
        <BaseText
          class="text-text-normal flex items-center"
          size="sm"
        >
          {{ paginationInfo.display }}
        </BaseText>
      </div>
    </div>
    <!-- Pages -->
    <div class="flex items-center gap-1">
      <button
        class="flex items-center gap-1 hover:bg-background-normal py-1.5 pr-2.5 rounded-md text-text-normal"
        :class="{'opacity-25 cursor-default' : pagination.currentPage === 1}"
        :disabled="pagination.currentPage === 1"
        @click="handlePageChange(pagination.currentPage-1)"
      >
        <div class="transform rotate-90">
          <ChevronIcon />
        </div>
        <BaseText
          type="label"
          size="md"
        >
          Prev
        </BaseText>
      </button>
      <button
        v-for="(page,i) in displayedPages"
        :key="`${page}-${i}`"
        class="text-text-normal cursor-auto rounded-md p-1 h-8 w-8 transition-colors"
        :class="{ 'bg-background-normal text-text-muted': pagination.currentPage === page, 'hover:bg-background-normal cursor-pointer' : page !== '...'}"
        @click="handlePageChange(page)"
      >
        {{ page }}
      </button>
      <button
        class="flex items-center gap-1 hover:bg-background-normal py-1.5 pl-2.5 rounded-md text-text-normal"
        :class="{'opacity-25 cursor-default' : pagination.currentPage === pageCount}"
        :disabled="pagination.currentPage === pageCount"
        @click="handlePageChange(pagination.currentPage+1)"
      >
        <BaseText
          type="label"
          size="md"
        >
          Next
        </BaseText>
        <div class="transform -rotate-90">
          <ChevronIcon />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'

export default {
  name: 'TablePagination',
  components: {
    ChevronIcon
  },
  mixins: [clickaway],
  props: {
    showing: {
      type: Number,
      default: () => 0
    },
    tablePagination: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showPaginationPopover: false,
      paginationOptions: [],
      pagination: { ...this.tablePagination } // Initialize pagination
    }
  },
  computed: {
    selectedLimit () {
      return this.paginationOptions.find((opt) => opt.value === this.pagination.limit)
    },
    pageCount () {
      return this.tablePagination.totalPages
    },
    middlePages () {
      if (!this.isMiddle) return []

      // Exclude first page and first ellipsis
      // Exclude last ellipsis and last page
      const items = this.displayedPages.slice(2, this.displayedPages.length - 2)

      // Filter out any ellipses within the middle
      return items.filter(num => num !== '...')
    },
    isMiddle () {
      const currentPage = this.pagination.currentPage
      return currentPage > 3 && currentPage < this.pageCount - 2
    },
    displayedPages () {
      const totalPages = this.pageCount
      const current = this.pagination.currentPage
      if (totalPages <= 7) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      }

      const pages = [1] // Always show first page

      if (current <= 3) {
        for (let i = 2; i <= 5; i++) {
          pages.push(i)
        }
        pages.push('...')
      } else if (current >= totalPages - 2) {
        pages.push('...')
        for (let i = totalPages - 4; i < totalPages; i++) {
          pages.push(i)
        }
      } else {
        const rangeStart = Math.max(2, current - 1)
        const rangeEnd = Math.min(totalPages - 1, current + 1)

        if (rangeStart > 2) {
          pages.push('...')
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
          pages.push(i)
        }

        if (rangeEnd < totalPages - 1) {
          pages.push('...')
        }
      }

      pages.push(totalPages) // Always show last page

      return pages
    },
    paginationInfo () {
      const totalItems = this.pagination.totalItems

      const lowPivot = (this.pagination.currentPage - 1) * (this.pagination.limit || 10)
      const lowValue = lowPivot === 0 ? 1 : lowPivot + 1
      const totalShown = Math.min(totalItems, lowPivot + this.pagination.limit)
      return { display: `${lowValue || 1}-${lowValue + this.showing - 1} of ${totalItems}`, totalShown }
    }
  },
  watch: {
    tablePagination: {
      handler (newVal) {
        this.pagination = { ...newVal }
      },
      deep: true
    }
  },
  mounted () {
    const totalPages = this.pagination.totalPages
    this.paginationOptions = Array.from({ length: totalPages }, (_, i) => (i + 1) * 10)
    this.pagination.limit = this.paginationOptions[0]
  },
  methods: {
    updateResultLimit (option) {
      this.pagination = {
        ...this.pagination,
        limit: option,
        currentPage: 1
      }
      this.$emit('updatePagination', { limit: option, currentPage: 1 })
    },
    handlePageChange (newPage) {
      if (newPage === '...') return
      if (newPage === this.pagination.currentPage) return
      this.pagination.currentPage = newPage
      this.$emit('updatePagination', { limit: this.pagination.limit, currentPage: newPage })
    }
  }
}
</script>

<style scoped>
  .dropdown-scrollable {
    transition: colors 250ms ease-in-out;
    bottom: calc(100% + 12px);
    z-index: 999;
  }
  .dropdown-scrollable::-webkit-scrollbar {
    width: 3px;
  }
  .dropdown-scrollable::-webkit-scrollbar-thumb {
    background-color: #DFE1E7;
    border-radius: 18px;
  }

</style>
