<template>
  <Transition name="fade-up">
    <div
      v-if="totalChanges > 0 || loadingInfo.type === 'success'"
      class="w-80 h-8 mx-auto report-edit-save-prompt fixed bottom-8 left-0 right-0 shadow-md text-white"
      :class=" {
        'prompt-success': loadingInfo.type === 'success',
        'prompt-edit': loadingInfo.type !== 'success'
      }
      "
    >
      <Transition
        name="slide-up"
        mode="out-in"
      >
        <div
          v-if="!loadingInfo.type && !loadingInfo.value"
          class="w-full h-full flex items-center justify-between p-2"
        >
          <div class="flex items-center gap-2">
            <div class="flex items-center gap-1.5 p-1.5">
              <EditIcon class="text-white" />
              <BaseText
                type="label"
                size="sm"
              >
                Edit Mode
              </BaseText>
            </div>
            <BaseText
              size="sm"
            >
              {{ totalChanges }} changes
            </BaseText>
          </div>
          <div class="flex items-center gap-1">
            <button
              class="bg-transparent text-neutral-alpha-650 px-3 py-1.5 rounded-md"
              @click="$emit('cancel')"
            >
              <BaseText
                type="label"
                size="sm"
              >
                Cancel
              </BaseText>
            </button>
            <button
              class="save-btn px-3 py-1.5 rounded-md"
              :disabled="loadingInfo.value"
              @click="handleSave"
            >
              <BaseLoadingSpinner
                v-if="loadingInfo.value"
                small
              />
              <BaseText
                v-else
                type="label"
                size="sm"
              >
                Save
              </BaseText>
            </button>
          </div>
        </div>
        <!-- Handle success & error save -->
        <div
          v-else-if="loadingInfo.type === 'success' && !loadingInfo.value"
          class="w-full h-full flex items-center gap-3 p-1 pr-3"
        >
          <div class="success-mark rounded-full">
            <CheckmarkIcon class="text-secondary-green-50" />
          </div>
          <BaseText
            type="label"
            size="sm"
          >
            {{ numofChanges }} changes saved successfully.
          </BaseText>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
// Icons
import EditIcon from '../../globals/Icons/EditIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
export default {
  name: 'ReportEditSavePrompt',
  components: {
    EditIcon,
    CheckmarkIcon
  },
  props: {
    originalReport: {
      type: Object,
      default: () => {}
    },
    changedReport: {
      type: Object,
      default: () => {}
    },
    loadingInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const changedKeys = ['sorted_column', 'pinned_columns', 'table_config.colorFormat', 'selected_columns', 'selected_graph_rows', 'group_by', 'filters', 'table_columns', 'preset_id']
    return {
      changedKeys,

      numofChanges: 0
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getIsCustomPreset']),
    totalChanges () {
      return this.changedKeys.reduce((acc, key) => {
        const changedSection = this.getValueByKey(this.changedReport, key)
        const originalSection = this.getValueByKey(this.originalReport, key)

        // If sections differ, or we changed something that doesn't exist in original count as a change
        if (!_.isEqual(changedSection, originalSection)) {
          // TODO @Sam: remove change # for preset changes
          if (key === 'preset_id') return acc
          return acc + 1
        }
        return acc
      }, 0)
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    handleBeforeUnload (e) {
      if (this.totalChanges > 0) {
        e.preventDefault()
        alert('You have unsaved changes. Are you sure you want to leave?')
      }
    },
    getValueByKey (obj, key) {
      const keys = key?.split('.')
      if (keys.length > 0) {
        return keys.reduce((acc, k) => (acc && acc[k] !== 'undefined') ? acc[k] : undefined, obj)
      }
      return obj[key]
    },
    async handleSave () {
      // Report updates require the exact object, deconstructing hash out of report to pass validation
      const { columns_hash: columnsHash, ...toUpdate } = this.changedReport
      this.numofChanges = this.totalChanges
      this.$emit('save', toUpdate)
    }
  }
}
</script>

<style scoped>
.prompt-edit{
  width: 360px;
  height: 48px;
}
.prompt-success{
  width: 263px;
  height: 36px;
  border-radius: 44px !important;
}
.report-edit-save-prompt {
    z-index: 999;
    border-radius: 14px;
    background: #24252D;
    transition: width 150ms, height 150ms, border-radius 150ms;
}
.save-btn{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)),
                linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)),
                linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 100%),
                linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 100%);
}

.fade-up-enter-active, .fade-up-leave-active {
  transition: opacity 150ms, transform 150ms;
}
.fade-up-enter, .fade-up-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
.slide-up-active, .slide-up-leave-active{
  transition: all 150ms;
}
.slide-up-enter, .slide-up-leave-to{
  opacity: 0;
  transform: translateY(1rem);
}
.success-mark{
  background: rgba(64, 196, 170, 0.44);
  height: 28px;
  width: 28px;
  text-align: center;
  padding: 4px;
}
</style>
