<template>
  <div class="graph-card pt-3 px-1 pb-1 rounded-lg bg-white">
    <!-- Graph Area -->
    <div ref="graphArea" class="relative flex-grow w-full flex items-end" style="padding-top: 22px;">
      <!-- Plot Lines -->
      <div class="absolute left-0 top-0 w-full h-full flex flex-col justify-between px-1"
      style="padding-top: 22px; z-index: 1;">
        <div v-for="n in (numPlotLines * 2) - 1" :key="`plot-line-${n}`" class="w-full h-0 border-t border-border-normal"/>
      </div>
      <!-- Graph -->
      <div class="relative w-full flex items-end justify-between z-10">
        <!-- Left Padding -->
        <div class="h-full" :style="{width: `${xDimensions.padding}px`}"/>
        <!-- Bars -->
        <div class="flex-grow flex justify-between items-end" :style="{maxWidth: `${maxBarAreaWidth}px`}"
        @mouseenter="barAreaHovered = true" @mouseleave="barAreaHovered = false">
          <div v-for="(kpi, index) in selectedKpis" :key="`bar-${data.group_by}-${kpi}`" 
          class="relative flex-shrink-0" :class="{'anim-bar': doAnimate}" style="min-height: 4px;"
          :style="{width: `${xDimensions.barWidth}px`, height: `${yDimensions[index]}px`, backgroundColor: getKpiColor(index), borderRadius: `${xDimensions.borderRadius}px`}"
          @transitionstart="initiateAnimLabelOffset">
            <!-- Bar Label -->
            <div class="bar-label pb-1">
              <BaseText ref="barLabel" type="label" size="xs" class="relative text-text-muted px-0.5">
                {{ formatBarLabelString(data[kpi], kpi) }}
              </BaseText>
            </div>
          </div>
        </div>
        <!-- Right Padding -->
        <div class="h-full" :style="{width: `${xDimensions.padding}px`}"/>
      </div>
    </div>
    <!-- Data Source Footer -->
    <div class="w-full flex justify-center min-w-0" style="height: 36px">
      <div class="footer-label p-1 transition-colors duration-100 hover:bg-neutral-25 cursor-default"
      @mouseenter="footerHovered = true" @mouseleave="footerHovered = false">
        <img :src="data.ads?.[0]?.ad_image_url" class="w-7 h-7 rounded-md object-cover">
        <BaseText type="body" size="xs" class="flex-shrink text-text-muted truncate ml-2 pr-1">
          {{ data.group_by }}
        </BaseText>
        <!-- Footer Buttons -->
        <transition name="buttons">
          <div v-if="footerHovered" class="footer-buttons ml-auto">
            <button class="p-1" @mouseenter="adDetailsHovered = true" @mouseleave="adDetailsHovered = false">
              <AdDetailsAnimatedIcon :is-hovered="adDetailsHovered" />
            </button>
            <button class="p-1 text-icon-normal transition-colors duration-100 hover:text-icon-muted">
              <CreativeInsightsIcon />
            </button>
          </div>
        </transition>
      </div>
    </div>
    <!-- Graph Hover Tooltip -->
    <transition>
      <BaseTrackingTooltip v-if="barAreaHovered" :left-right-pos-override="tooltipPosOverride">
        <div class="graph-tooltip flex flex-col gap-1 p-1 rounded-lg shadow-md">
          <!-- Ad Details -->
          <div class="flex flex-col gap-0.5 p-1.5">
            <BaseText type="body" size="sm" class="text-white" style="max-width: 280px">
              {{ data.group_by }}
            </BaseText>
            <BaseText type="body" size="xs" class="text-neutral-alpha-650">
              {{ dateRangeLabel }}
            </BaseText>
          </div>
          <!-- Metric Values -->
          <div class="metrics w-full flex flex-col gap-2 py-2 px-1">
            <div v-for="(kpi, index) in selectedKpis" :key="`metric-${cardIndex}-${kpi}`" class="flex gap-1.5 items-center px-1">
              <div class="w-2.5 h-2.5 rounded-sm" :style="{backgroundColor: getKpiColor(index)}"/>
              <!-- Metric Name -->
              <BaseText type="body" size="sm" class="text-neutral-alpha-700 capitalize mr-2">
                {{ getMetricName(kpi) }}
              </BaseText>
              <!-- Metric Value -->
              <BaseText type="label" size="sm" class="text-white ml-auto">
                {{ formatBarLabelString(data[kpi], kpi) }}
              </BaseText>
              <!-- Percent Deviation -->
              <BaseText v-if="percentDeviations?.[index]" type="body" size="sm" class="ml-1"
              :class="`text-${percentDeviations[index].textColor}`">
                {{ percentDeviations[index].value }}
              </BaseText>
            </div>
          </div>
        </div>
      </BaseTrackingTooltip>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scaleLinear } from 'd3-scale'
import { getMatchingPresetName, formatCustomDateRangeLabel } from '../../../../utils/dateUtils'
import kpiColors from '../../../../utils/lens/selectedMetricColors'
import formatLabelString from '../../../../utils/lens/formatGraphLabelString'

import AdDetailsAnimatedIcon from '../../../globals/Icons/AdDetailsAnimatedIcon.vue'
import CreativeInsightsIcon from '../../../globals/Icons/LensIcons/CreativeInsightsIcon.vue'

const MAX_BAR_WIDTH = 28
const MIN_BAR_WIDTH = 8
const MAX_SPACE_BETWEEN_BARS = 40

export default {
  name: 'BarGraphCard',
  components: {
    AdDetailsAnimatedIcon,
    CreativeInsightsIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    axisScales: {
      type: Array,
      default: () => []
    },
    numPlotLines: {
      type: Number,
      default: 0
    },
    cardIndex: {
      type: Number,
      default: 0
    },
    totalCards: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      xDimensions: {
        padding: 0,
        barWidth: 0,
        borderRadius: 0
      },
      yDimensions: [],
      // dateFilterLabel: 'All Time',
      footerHovered: false,
      adDetailsHovered: false,
      barAreaHovered: false,
      resizeObserver: null,
      doAnimate: true, // TODO: @Sam - Maybe make this a part of table settings?
      animatingLabelOffset: false,
    }
  },
  watch: {
    // dateRange: {
    //   handler (newVal) {
    //     console.log('newVal', newVal)
    //     const [startDate, endDate] = newVal
    //     if (!startDate || !endDate) {
    //       this.dateFilterLabel = 'All Time'
    //     } else {
    //       this.dateFilterLabel = getMatchingPresetName({ start: startDate, end: endDate }) 
    //         || formatCustomDateRangeLabel(startDate, endDate)
    //     }
    //   },
    // }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    maxBarAreaWidth () {
      const numBars = this.selectedKpis.length
      return (numBars * this.xDimensions.barWidth) + ((numBars - 1) * MAX_SPACE_BETWEEN_BARS)
    },
    tooltipPosOverride () {
      const isLeftSide = this.cardIndex >= (this.totalCards / 2)
      return isLeftSide ? 'left' : 'right'
    },
    percentDeviations () {
      return this.selectedKpis.map(kpi => {
        const deviationValue = this.data?.percent_deviation?.[kpi]
        if (!deviationValue) return null
        const formattedValue = `${deviationValue.toFixed(1)}%`
        const textColor = deviationValue >= 0 ? 'secondary-green-50' : 'secondary-red-50'
        return {
          value: formattedValue,
          textColor
        }
      })
    },
    dateRangeLabel () {
      const { startDate, endDate } = this.dataSummary
      if (!startDate || !endDate) {
        return 'All Time'
      } else {
        return getMatchingPresetName({ start: startDate, end: endDate }) 
          || formatCustomDateRangeLabel(startDate, endDate)
      }
    }
  },
  watch: {
    'selectedKpis.length': {
      handler () {
        this.$nextTick(() => {
          this.computeBarStyles()
        })
      }
    },
    axisScales: {
      handler () {
        this.computeBarHeights()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeBarStyles()
      this.computeBarHeights()

      // Initialize a resize observer to watch the graph area
      this.resizeObserver = new ResizeObserver(() => {
        this.computeBarStyles()
      })
      this.resizeObserver.observe(this.$refs.graphArea)
    })
  },
  beforeDestroy () {
    // Cleanup the resize observer
    if (this.resizeObserver && this.$refs.graphArea) {
      this.resizeObserver.unobserve(this.$refs.graphArea)
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    formatBarLabelString (value, kpi) {
      const type = this.getMetricLookup?.[kpi]?.type
      return formatLabelString(value, type)
    },
    computeBarStyles () {
      // Get the width of the graph area
      if (!this.$refs.graphArea) return
      const graphAreaWidth = this.$refs.graphArea.getBoundingClientRect().width

      // Compute the widths
      const numBars = this.selectedKpis.length
      const sizeDividend = graphAreaWidth / numBars
      const xPadding = sizeDividend / 1.75
      const barWidth = Math.min(Math.max(sizeDividend / 4, MIN_BAR_WIDTH), MAX_BAR_WIDTH)
      const brFactorScale = scaleLinear([MIN_BAR_WIDTH, MAX_BAR_WIDTH], [0.35, 0.2])
      const borderRadius = barWidth * brFactorScale(barWidth)

      // Set the data
      this.xDimensions = {
        padding: xPadding,
        barWidth: barWidth,
        borderRadius: borderRadius
      }
      this.$nextTick(() => {
        this.handleBarLabelCollision()
      })
    },
    computeBarHeights () {
      const clientHeight = this.$refs.graphArea.clientHeight
      const computedStyle = window.getComputedStyle(this.$refs.graphArea)
      const paddingTop = parseFloat(computedStyle.paddingTop)
      const barAreaHeight = clientHeight - paddingTop

      const yDimensions = []
      for (const [index, scale] of this.axisScales.entries()) {
        scale.range([0, barAreaHeight])
        const value = this.data[this.selectedKpis[index]]
        const height = scale(value)
        yDimensions.push(height)
      }
      this.$nextTick(() => {
        this.yDimensions = yDimensions
        this.handleBarLabelCollision()
      })
    },
    handleBarLabelCollision () {
      const barLabels = this.$refs.barLabel?.map(label => label.$el)
      if (!barLabels || barLabels.length <= 1) return
      barLabels.forEach(label => { label.style.top = '0px' })

      const getCollisionStatus = (rectA, rectB) => {
        // Returns true if the bounding boxes overlap
        return (
          rectA.left < rectB.left + rectB.width &&
          rectA.left + rectA.width > rectB.left &&
          rectA.top < rectB.top + rectB.height &&
          rectA.top + rectA.height > rectB.top
        )
      }

      // Compare each adjacent pair of labels
      for (let i = 0; i <= barLabels.length - 2; i++) {
        const labelA = barLabels[i]
        const labelB = barLabels[i + 1]
        const labelARect = labelA.getBoundingClientRect()
        const labelBRect = labelB.getBoundingClientRect()
        if (getCollisionStatus(labelARect, labelBRect)) {
          // If a collision is detected, determine which label is lower is shift it down
          if (labelARect.top > labelBRect.top) {
            const shiftOffset = (labelBRect.top + labelBRect.height) - labelARect.top
            labelA.style.top = `${shiftOffset}px`
          } else {
            const shiftOffset = (labelARect.top + labelARect.height) - labelBRect.top
            labelB.style.top = `${shiftOffset}px`
          }
        }
      }
    },
    getKpiColor (index) {
      return kpiColors[index]
    },
    getMetricName (kpi) {
      return this.getMetricLookup?.[kpi]?.name || kpi
    },
    initiateAnimLabelOffset () {
      if (!this.doAnimate || this.animatingLabelOffset) return
      this.animatingLabelOffset = true

      // If we are animating, continuously compute the label offset until the animation is complete
      const startTime = performance.now()
      const animateLabelOffset = (currentTime) => {
        if (currentTime - startTime < 500) { // 500ms is the duration of the animation
          this.handleBarLabelCollision()
          requestAnimationFrame(animateLabelOffset)
        } else {
          this.animatingLabelOffset = false
        }
      }
      requestAnimationFrame(animateLabelOffset)
    }
  }
}
</script>

<style scoped>
.graph-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-width: 0px;
  box-shadow: 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
  container-type: inline-size;
}
.anim-bar {
  height: 4px;
  transition: height 500ms ease-in-out;
}
.bar-label {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  text-shadow:
    -1px -1px 0 #fff,
     1px -1px 0 #fff,
    -1px  1px 0 #fff,
     1px  1px 0 #fff;
}
.graph-tooltip {
  min-width: 220px;
  /* max-width: 280px; */
  background: rgba(6, 7, 16, 0.88);
  backdrop-filter: blur(12px);
}
.graph-tooltip .metrics {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

/* ===== CARD DETAILS FOOTER STYLING/ANIMATIONS ===== */
.footer-label {
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-radius: 10px;
  min-width: 0px;
}
.footer-buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  width: 56px;
}
@container (min-width: 220px) {
  .footer-label {
    flex-grow: 0;
  }
}
.buttons-enter-active {
  transition: width 150ms ease-in-out, opacity 75ms ease-in-out 75ms;
}
.buttons-leave-active {
  transition: width 150ms ease-in-out, opacity 75ms ease-in-out;
}
.buttons-enter-from, .buttons-enter, .buttons-leave-to {
  width: 0px;
  opacity: 0;
}
.buttons-enter-to, .buttons-leave-from {
  width: 56px;
  opacity: 1;
}

.v-enter-active, .v-leave-active {
  transition: opacity 50ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

.bar-item{
  transition: height 0.6s cubic-bezier(0.76, 0, 0.24, 1);
}
</style>
